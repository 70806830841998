import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../components/Loader';

export class Edituser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {},
      id: '',
      name: '',
      dob: moment(new Date()).format('YYYY-MM-DD'),
      gender: '',
      contact: '',
      email: '',
      profile_img: '',
      weight: '',
      weight_type: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchuserprofile(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.fetchuserprofile(this.props.id);
    }
  };

  fetchuserprofile = (id) => {
    fetch(global.api_link + 'getUserProfile/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            name: json.users.name,
            // dob: moment
            //   .tz(json.users.dob, 'DD/MM/YYYY', 'Asia/Kolkata')
            //   .format('ddd MMM D YYYY HH:mm:ss [GMT]ZZ (Indian Standard Time)'),
            dob: moment(json.users.dob).format('YYYY-MM-DD'),
            contact: json.users.contact,
            gender: json.users.gender,
            weight: json.users.weight,
            weight_type: json.users.weight_type,
            profile_img: json.users.profile_picture,
            email: json.users.email,
          });
        } else {
          this.setState([]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  editUSer = (e) => {
    e.preventDefault();
    this.setState({ isloading: true });
    fetch(global.api_link + 'userProfileUpdate/' + this.props.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        dob: this.state.dob,
        gender: this.state.gender,
        contact: this.state.contact,
        email: this.state.email,
        profile_img: this.state.profile_img,
        weight: this.state.weight,
        weight_type: this.state.weight_type,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Profile Upated Succesfully...');
          this.props.navigate('/user', { replace: true });
        } else {
          this.setState([]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isloading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />

          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className="">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />
                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">Edit User</h2>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <form
                          onSubmit={(e) => {
                            this.editUSer(e);
                          }}
                        >
                          <div className="col-xs-12">
                            <div className="form-group">
                              <label className="form-label">Name</label>
                              <span className="desc" />
                              <div className="controls">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.name}
                                  onChange={(e) => {
                                    this.setState({ name: e.target.value });
                                  }}
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">
                                    Date of Birth
                                  </label>

                                  <div className="controls">
                                    <input
                                      type="date"
                                      value={this.state.dob}
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                      }}
                                      className="form-control w-100"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Gender</label>
                                  <div className="controls">
                                    <select
                                      className="form-control"
                                      value={this.state.gender}
                                      onChange={(e) => {
                                        this.setState({
                                          gender: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Weight</label>
                                  <span className="desc" />
                                  <div className="controls">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.weight}
                                      onChange={(e) =>
                                        this.setState({
                                          weight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Type</label>
                                  <div className="controls">
                                    <select
                                      className="form-control"
                                      value={this.state.weight_type}
                                      onChange={(e) =>
                                        this.setState({
                                          weight_type: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="kg">Kg</option>
                                      <option value="pounds">Pounds</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label" for="phone">
                                Contact
                              </label>

                              <div className="controls">
                                <input
                                  type="text"
                                  placeholder="Contact"
                                  className="form-control"
                                  value={this.state.contact}
                                  onChange={(e) =>
                                    this.setState({
                                      contact: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label">
                                Email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({
                                    email: e.target.value,
                                  })
                                }
                              />
                            </div>

                            {/* <div className="form-group">
                                  <label className="form-label">
                                    Profile Image
                                  </label>
                                  <span className="desc" />
                                  <img
                                    className="img-responsive"
                                    src="../data/profile/avatar-5.png"
                                    alt=""
                                    style={{ maxWidth: 120 }}
                                  />
                                  <div className="controls">
                                    <input
                                      type="file"
                                      className="form-control"
                                      value={this.state.profile_img}
                                      onChange={(e) =>
                                        this.setState({
                                          profile_img: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div> */}
                          </div>

                          <div className="pull-right">
                            {this.state.isLoading ? (
                              <button className="btn btn-primary rounded disabled">
                                <div class="spinner-border" role="status"></div>
                                Please wait
                              </button>
                            ) : (
                              <button className="btn btn-primary rounded">
                                UPDATE
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Edituser {...props} {...useParams()} navigate={abcd} location={location} />
  );
}

export default (props) => <Navigate {...props} />;
