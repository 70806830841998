import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const Hospital = () => {
  const [hospitals, setHospitals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const showAlert = (e) => {
    Swal.fire({
      title: ' Do you want to Delete this hospital ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteHospital/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('Hospital Deleted Successfully');
              fetchHospitals();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  const fetchHospitals = () => {
    fetch(global.api_link + 'getHospitalList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          setHospitals(json.hospitals);
        } else {
          setHospitals([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchHospital = (hospital) => {
    fetch(global.api_link + 'search_hospital?query=' + hospital, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status) {
          return res.json();
        }
      })
      .then((json) => {
        if (json.status) {
          setHospitals(json.data);
        } else {
          setHospitals([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Header />
      <Sidenav />

      {isLoading ? (
        <Loader />
      ) : (
        <section id="main-content" className=" ">
          <div className="wrapper main-wrapper row">
            <div class="col-md-10 align-items-stretch my-3">
              <input
                className="form-control mx-2 mt-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  searchHospital(e.target.value);
                }}
              />
            </div>

            <div class="col-lg-2 d-flex justify-content-end my-3">
              <Link to="/addhospital">
                {' '}
                <button
                  type="button"
                  className="btn btn-success btn-icon btn-lg mt-10"
                >
                  <span>Add Hospital</span>
                </button>
              </Link>
            </div>
            {hospitals.length > 0 ? (
              <div className="col-lg-12">
                <section className="box ">
                  {hospitals.length > 0 ? (
                    <div className="content-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table vm table-small-font no-mb table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Sno</th>
                                  <th>Image</th>
                                  <th>Name</th>
                                  <th>Address</th>
                                  <th>Email</th>
                                  <th>Contact</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {hospitals.map((values, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <Link
                                          to={'/hospitalprofile/' + values.id}
                                          target="_blank"
                                        >
                                          <img
                                            src={global.img_link + values.image}
                                            alt="image"
                                            style={{
                                              height: '40px',
                                              width: '40px',
                                            }}
                                          />
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={'/hospitalprofile/' + values.id}
                                          target="_blank"
                                        >
                                          {values.name}
                                        </Link>
                                      </td>
                                      <td>
                                        {values.house_number} {values.street1}{' '}
                                        {values.street2} {values.city}{' '}
                                        {values.state} {values.pincode}
                                      </td>
                                      <td>{values.email}</td>
                                      <td>
                                        {values.country_code !== null &&
                                          values.country_code + '-'}
                                        {values.contact}
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center gap-2">
                                          <Link
                                            to={'/edithospital/' + values.id}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                            >
                                              <i className="fa-solid fa-pencil" />
                                            </button>
                                          </Link>
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              showAlert(values.id);
                                            }}
                                          >
                                            <i className="fa-solid fa-trash" />
                                          </button>

                                          <Link
                                            to={
                                              '/addhospitalservices/' +
                                              values.id
                                            }
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                            >
                                              Services
                                            </button>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </section>
              </div>
            ) : (
              <h3>
                <center>No Hospitals Found</center>
              </h3>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Hospital;
