import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchUser, setSearchUser] = useState('');

  const showAlert = (e) => {
    Swal.fire({
      title: ' Do you want to Delete this user ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteUser/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('User Deleted Successfully');
              fetchUsers();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    fetch(global.api_link + 'getUserList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          setUsers(json.users);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const search = (user) => {
    fetch(global.api_link + 'search_user?query=' + user, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status) {
          return res.json();
        }
      })
      .then((json) => {
        if (json.status) {
          setUsers(json.data);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header />
      <div className="page-container row-fluid container-fluid">
        <Sidenav />
        {isLoading ? (
          <Loader />
        ) : (
          <section id="main-content" className=" ">
            <div className="wrapper main-wrapper row">
              <div class="col-md-10 align-items-stretch my-3">
                <input
                  className="form-control mx-2 mt-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchUser}
                  onChange={(e) => {
                    search(e.target.value);
                    setSearchUser(e.target.value);
                  }}
                />
              </div>
              <div class="col-lg-2 d-flex  justify-content-end my-3">
                <Link to="/adduser">
                  {' '}
                  <button
                    type="button"
                    className="btn btn-success btn-icon btn-lg mt-10"
                  >
                    <span>Add User</span>
                  </button>
                </Link>
              </div>
              {users.length > 0 ? (
                <div className="col-lg-12">
                  <section className="box ">
                    {users.length > 0 ? (
                      <div className="content-body">
                        <div className="row">
                          <div className="col-xs-12">
                            <div
                              className="table-responsive"
                              data-pattern="priority-columns"
                            >
                              <table
                                id="tech-companies-1"
                                className="table vm table-small-font no-mb table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th>Sno</th>
                                    <th>Name</th>
                                    <th>DOB</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Weight</th>
                                    <th>Gender</th>
                                    <th>Registered Date</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users.map((values, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>

                                        <td>{values.name}</td>
                                        <td>
                                          <span>
                                            {moment(values.dob).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </span>
                                        </td>
                                        <td>{values.email}</td>
                                        <td>
                                          {values.country_code !== null &&
                                            values.country_code + '-'}
                                          {values.contact}
                                        </td>
                                        <td>
                                          {values.weight} {values.weight_type}
                                        </td>
                                        <td
                                          style={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {values.gender}
                                        </td>
                                        <td>
                                          {moment(values.created_at).format(
                                            'llll'
                                          )}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center gap-2">
                                            <Link
                                              to={'/userprofile/' + values.id}
                                              type="button"
                                              className="btn btn-warning"
                                            >
                                              <i className="fa-solid fa-eye" />
                                            </Link>
                                            <Link to={'/edituser/' + values.id}>
                                              <button
                                                type="button"
                                                className="btn btn-success"
                                              >
                                                <i className="fa-solid fa-pencil" />
                                              </button>
                                            </Link>
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => {
                                                showAlert(values.id);
                                              }}
                                            >
                                              <i className="fa-solid fa-trash" />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </section>
                </div>
              ) : (
                <h3>
                  <center>No Users Found</center>
                </h3>
              )}
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Users;
