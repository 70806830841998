import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchDoctor, setSearchDoctor] = useState('');
  const showAlert = (e) => {
    Swal.fire({
      title: ' Do you want to Delete ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteDoctor/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('Doctor Deleted Successfully');
              fetchDoctors();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = () => {
    fetch(global.api_link + 'getDoctorList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          setDoctors(json.doctors);
        } else {
          setDoctors([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const search = (doctor) => {
    fetch(global.api_link + 'search_doctor?query=' + doctor, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status) {
          return res.json();
        }
      })
      .then((json) => {
        if (json.status) {
          setDoctors(json.data);
        } else {
          setDoctors([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header />

      <div className="page-container row-fluid container-fluid">
        {/* SIDEBAR - START */}
        <Sidenav />
        {isLoading ? (
          <Loader />
        ) : (
          <section id="main-content" className=" ">
            <div className="wrapper main-wrapper row">
              <div class="col-md-10 d-flex align-items-center my-3">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchDoctor}
                  onChange={(e) => {
                    search(e.target.value);
                    setSearchDoctor(e.target.value);
                  }}
                />
              </div>

              <div class="col-lg-2 d-flex align-items-center justify-content-end my-3">
                <Link to="/adddoctor">
                  {' '}
                  <button
                    type="button"
                    className="btn btn-success btn-icon btn-lg"
                  >
                    <span>Add Doctor</span>
                  </button>
                </Link>
              </div>
              <div className="col-lg-12">
                <section className="box ">
                  {doctors.length > 0 ? (
                    <div className="content-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table vm table-small-font no-mb table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Sno</th>
                                  <th>Image</th>
                                  <th>Name</th>
                                  <th>Status</th>
                                  <th>Contact</th>
                                  <th>Email</th>
                                  {/* <th>Hospital</th> */}
                                  <th> Doctor type</th>
                                  <th>Address</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {doctors.map((values, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <Link
                                          to={'/doctorprofile/' + values.id}
                                          target="_blank"
                                        >
                                          <img
                                            src={global.img_link + values.image}
                                            alt="image"
                                            style={{
                                              height: '40px',
                                              width: '40px',
                                            }}
                                          />
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={'/doctorprofile/' + values.id}
                                          target="_blank"
                                        >
                                          {values.name}
                                        </Link>
                                      </td>
                                      <td>{values.status}</td>
                                      <td>
                                        <span>{values.contact}</span>
                                      </td>

                                      <td>{values.email}</td>
                                      {/* <td>{values.hospital_name}</td> */}
                                      <td>{values.category_name}</td>
                                      <td>{values.address}</td>

                                      <td>
                                        <div className="d-flex align-items-center gap-2">
                                          {/* <Link
                                            to={'/doctorprofile/' + values.id}
                                            type="button"
                                            className="btn btn-warning"
                                          >
                                            <i className="fa-solid fa-eye" />
                                          </Link> */}
                                          <Link to={'/editdoctor/' + values.id}>
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                            >
                                              <i className="fa-solid fa-pencil" />
                                            </button>
                                          </Link>
                                          {/* <Link
                                            to={
                                              '/adddoctorservices/' + values.id
                                            }
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                            >
                                              <i className="fa-brands fa-servicestack" />
                                            </button>
                                          </Link> */}
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              showAlert(values.id);
                                            }}
                                          >
                                            <i className="fa-solid fa-trash" />
                                          </button>

                                          <Link
                                            to={'/addservices/' + values.id}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                            >
                                              <i className="fa-brands fa-servicestack" />
                                            </button>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h3>
                      <center>No Doctors Found</center>
                    </h3>
                  )}
                </section>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Doctors;
