import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

export class Addservice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addLoading: false,
      isLoading: true,
      serviceList: [],
      open: false,
      openEdit: false,
      editLoading: false,
      add_service_name: '',
      edit_service_id: '',
      edit_service_name: '',
    };
  }

  componentDidMount() {
    this.getServiceList();
  }

  addService = () => {
    this.setState({ addLoading: true });
    fetch(global.api_link + 'addService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        service_name: this.state.add_service_name,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.getServiceList();
          this.setState({
            addLoading: false,
            add_service_name: '',

            open: false,
          });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addLoading: false });
      });
  };

  editService = () => {
    this.setState({ editLoading: true });
    fetch(
      global.api_link + 'serviceProfileUpdate/' + this.state.edit_service_id,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          service_name: this.state.edit_service_name,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.getServiceList();
          this.setState({
            editLoading: false,
            edit_service_id: '',
            edit_service_name: '',

            openEdit: false,
          });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ editLoading: false });
      });
  };

  deleteService = (e) => {
    Swal.fire({
      title: ' Do you want to Delete this Service ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteService/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('Service Deleted Successfully');
              this.getServiceList();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  getServiceList = () => {
    fetch(global.api_link + 'getServiceList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ serviceList: json.profile });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className="">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />
                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">Services</h2>
                      <div className="title pull-right ps-0 pe-3">
                        <button
                          className="btn btn-primary btn-corner"
                          onClick={() =>
                            this.setState({
                              open: true,
                            })
                          }
                        >
                          Add Service
                        </button>
                      </div>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Sno</th>
                                <th>Service Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.serviceList.map((values, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{values.service_name}</td>

                                    <td>
                                      <div className="d-flex align-items-center gap-2">
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={() => {
                                            this.setState({
                                              openEdit: true,
                                              edit_service_id: values.id,
                                              edit_service_name:
                                                values.service_name,
                                            });
                                          }}
                                        >
                                          <i className="fa-solid fa-pencil" />
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => {
                                            this.deleteService(values.id);
                                          }}
                                        >
                                          <i className="fa-solid fa-trash" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
        {/* add modal */}
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Add Service</h3>

                <div className="form-group">
                  <div className="controls">
                    <label className="form-label mt-2" htmlFor="pro-start-date">
                      Service Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.add_service_name}
                      onChange={(e) => {
                        this.setState({ add_service_name: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addLoading ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.addService();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Edit modal */}
        <Modal
          open={this.state.openEdit}
          onClose={() => this.setState({ openEdit: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Edit Service</h3>
                <div className="form-group">
                  <div className="controls">
                    <label className="form-label mt-2" htmlFor="pro-start-date">
                      Service
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.edit_service_name}
                      onChange={(e) => {
                        this.setState({ edit_service_name: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.editLoading ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.editService();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Addservice
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
