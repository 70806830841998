import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Users from './pages/Users';
import Doctors from './pages/Doctors';
import { Slide, ToastContainer } from 'react-toastify';
import { AuthContext } from './AuthContextProvider';
import { RequireAuth } from './RequireAuth';
import Edituser from './pages/Edituser';
import Userprofile from './pages/Userprofile';
import Editdoctor from './pages/Editdoctor';
import Doctorprofile from './pages/Doctorprofile';
import Hospital from './pages/Hospital';
import HospitalProfile from './pages/HospitalProfile';
import Adduser from './pages/Adduser';
import Edithospital from './pages/Edithospital';
import Disease from './pages/Disease';
import Subcategory from './pages/Subcategory';
import Questions from './pages/Questions';
import AddServices from './pages/AddServices';
import Addhospital from './pages/Addhospital';
import Adddoctor from './pages/Adddoctor';
import AddHospitalServices from './pages/AddHospitalServices';
import Questionpage from './pages/Questionpage';
import Bookings from './pages/Bookings';
import BookingDetails from './pages/BookingDetails';
import Addservice from './pages/Addservice';

global.api_link = process.env.REACT_APP_API_URL;

global.img_link = process.env.REACT_APP_IMG_URL;

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      token: '',
      is_login: true,
      isLoading: true,
    };
  }

  componentDidMount() {
    const item = JSON.parse(localStorage.getItem('@auth_login'));
    if (item != null) {
      // this.get_user_profile(item.token);
      this.login(item.token);
    } else {
      this.logout();
    }
  }

  login = (token) => {
    this.setState({
      // user: user,
      token: token,
      isLoading: false,
      is_login: true,
    });
  };

  // get_user_profile = (token) => {
  //   fetch(global.api_link + '', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //       Authorization: token,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (json.message === 'Unauthenticated.') {
  //         this.logout();
  //       } else {
  //         this.login(json.data[0], token);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       this.setState({ isLoading: false });
  //     });
  // };

  logout = () => {
    this.setState({ user: {}, token: '', isLoading: false, is_login: false });
    localStorage.removeItem('@auth_login');
  };

  render() {
    return (
      <>
        {/* {this.state.isLoading ? (
          <p>Loader...</p>
        ) : (
          <> */}
        <AuthContext.Provider
          value={{
            login: this.login,
            logout: this.logout,
            is_login: this.state.is_login,
            user: this.state.user,
            token: this.state.token,
          }}
        >
          <Routes>
            <Route
              exact
              path="/home"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/user"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/doctors"
              element={
                <RequireAuth>
                  <Doctors />
                </RequireAuth>
              }
            />

            <Route
              path="/edituser/:id"
              element={
                <RequireAuth>
                  <Edituser />
                </RequireAuth>
              }
            />
            <Route
              path="/userprofile/:id"
              element={
                <RequireAuth>
                  <Userprofile />
                </RequireAuth>
              }
            />
            <Route
              path="/editdoctor/:id"
              element={
                <RequireAuth>
                  <Editdoctor />
                </RequireAuth>
              }
            />
            <Route
              path="/doctorprofile/:id"
              element={
                <RequireAuth>
                  <Doctorprofile />
                </RequireAuth>
              }
            />

            <Route
              path="/adddoctor"
              element={
                <RequireAuth>
                  <Adddoctor />
                </RequireAuth>
              }
            />
            <Route
              path="/hospital"
              element={
                <RequireAuth>
                  <Hospital />
                </RequireAuth>
              }
            />
            <Route
              path="/hospitalprofile/:id"
              element={
                <RequireAuth>
                  <HospitalProfile />
                </RequireAuth>
              }
            />

            <Route
              path="/addhospital"
              element={
                <RequireAuth>
                  <Addhospital />
                </RequireAuth>
              }
            />

            <Route
              path="/adduser"
              element={
                <RequireAuth>
                  <Adduser />
                </RequireAuth>
              }
            />

            <Route
              path="/edithospital/:id"
              element={
                <RequireAuth>
                  <Edithospital />
                </RequireAuth>
              }
            />

            <Route
              path="/disease"
              element={
                <RequireAuth>
                  <Disease />
                </RequireAuth>
              }
            />

            <Route
              path="/subcategory"
              element={
                <RequireAuth>
                  <Subcategory />
                </RequireAuth>
              }
            />

            <Route
              path="/questions"
              element={
                <RequireAuth>
                  <Questions />
                </RequireAuth>
              }
            />

            <Route
              path="/questionspage/:id"
              element={
                <RequireAuth>
                  <Questionpage />
                </RequireAuth>
              }
            />

            <Route
              path="/addservices/:id"
              element={
                <RequireAuth>
                  <AddServices />
                </RequireAuth>
              }
            />

            <Route
              path="/addhospitalservices/:id"
              element={
                <RequireAuth>
                  <AddHospitalServices />
                </RequireAuth>
              }
            />

            <Route
              path="/bookingdetails/:id"
              element={
                <RequireAuth>
                  <BookingDetails />
                </RequireAuth>
              }
            />

            <Route
              path="/bookings"
              element={
                <RequireAuth>
                  <Bookings />
                </RequireAuth>
              }
            />

            <Route
              path="/addservice"
              element={
                <RequireAuth>
                  <Addservice />
                </RequireAuth>
              }
            />
            <Route path="/" element={<Login />} />
          </Routes>
        </AuthContext.Provider>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="dark"
          transition={Slide}
        />
      </>
    );
  }
}

export default App;
