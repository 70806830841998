import React, { Component } from "react";
import Header from "../components/Header";
import Sidenav from "../components/Sidenav";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      questionList: [],
      editSelectedParentId: [],
      buttonLoader: false,
    };
  }

  componentDidMount() {
    this.getQuestionList();
  }

  getQuestionList = () => {
    fetch(global.api_link + "getQuestionList", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ questionList: json.data });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.getDiseaseQuestion();
      });
  };

  getDiseaseQuestion = () => {
    fetch(global.api_link + "getDiseaseQuestion/" + this.props.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ editSelectedParentId: json.question });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  addDiseaseQuestion = () => {
    this.setState({ buttonLoader: true });
    fetch(global.api_link + "addDiseaseQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        disease_id: this.props.id,
        disease_questions: this.state.editSelectedParentId,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.props.navigate("/disease");
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ buttonLoader: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />

          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className=" ">
              <div className="wrapper main-wrapper row">
                <div className="col-lg-12">
                  <div class="row">
                    <div class="col-md-10 align-items-stretch my-3">
                      <input
                        className="form-control mx-2 mt-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end my-3">
                      {this.state.buttonLoader ? (
                        <button className="btn btn-success btn-icon btn-lg mt-10 disabled">
                          <div className="spinner-border" role="status"></div>
                          Please wait
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success btn-icon btn-lg mt-10"
                          onClick={() => {
                            this.addDiseaseQuestion();
                          }}
                        >
                          <span>Save Question</span>
                        </button>
                      )}
                    </div>
                  </div>

                  <section className="box nobox marginBottom0">
                    <div className="content-body">
                      <div className="row my-4">
                        {this.state.questionList.map((values, index) => {
                          return (
                            <div className="col-md-4 mb-2">
                              <label htmlFor={index} className="w-100">
                                <div className="db_box">
                                  <div class="row d-flex justify-content-start ">
                                    <div class="col-md-1">
                                      <input
                                        type="checkbox"
                                        name=""
                                        id={index}
                                        className="m-0"
                                        style={{
                                          height: "15px",
                                        }}
                                        checked={this.state.editSelectedParentId.includes(
                                          values.id
                                        )}
                                        value={values.id}
                                        onChange={(e) => {
                                          if (
                                            this.state.editSelectedParentId.includes(
                                              values.id
                                            )
                                          ) {
                                            this.setState({
                                              editSelectedParentId:
                                                this.state.editSelectedParentId.filter(
                                                  (x) => x !== values.id
                                                ),
                                            });
                                          } else {
                                            this.setState({
                                              editSelectedParentId: [
                                                ...this.state
                                                  .editSelectedParentId,
                                                values.id,
                                              ],
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-11">
                                      <p>{values.question_name}</p>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {/* End .row */}
                    </div>
                  </section>
                </div>
                <div className="clearfix" />
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Home {...props} {...useParams()} navigate={abcd} location={location} />
  );
}

export default (props) => <Navigate {...props} />;
