import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select from 'react-select';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';

export class Editdoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: {},
      name: '',
      dob: moment(new Date()).format('YYYY-MM-DD'),
      gender: '',
      contact: '',
      email: '',
      profile_image: '',
      address: '',
      hospital_name: '',
      isLoading: true,
      autoaddress: '',
      house_number: '',
      address: '',
      address2: '',
      state: '',
      city: '',
      pincode: '',
      latitude: '',
      longitude: '',
      images: [],
      selected_formatted_address: '',
      optionList: [],
      selectedOptions: [],
      selectedDoctors: [],
      country_code_selected: '',
      editdoctorImage: '',
      experience: '',
      about: '',
      doctor_type: '',
      category: [],
      status: 'active',
    };
  }

  handleChange = (address) => {
    this.setState({ selected_formatted_address: address });
  };
  componentDidMount() {
    this.fetchdoctorsprofile(this.props.id);
    this.fetchCategory();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.fetchdoctorsprofile(this.props.id);
    }
  };

  fetchCategory = () => {
    fetch(global.api_link + 'getcategoryList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.disease_category,
            isLoading: false,
          });
        } else {
          this.setState({
            category: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  fetchdoctorsprofile = (id) => {
    fetch(global.api_link + 'getDoctorProfile/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            name: json.doctors.name,
            dob: moment(json.doctors.dob).format('YYYY-MM-DD'),
            contact: json.doctors.contact,
            gender: json.doctors.gender,
            email: json.doctors.email,
            address: json.doctors.street1,
            address2: json.doctors.street2,
            hospital_name: json.doctors.hospital_name,
            country_code_selected: json.doctors.country_code,
            pincode: json.doctors.pincode,
            state: json.doctors.state,
            city: json.doctors.city,
            selected_formatted_address: json.doctors.address,
            house_number: json.doctors.house_number,
            experience: json.doctors.experience,
            editdoctorImage: json.doctors.profile_image,
            about: json.doctors.description,
            doctor_type: json.doctors.doctor_type,
            longitude: json.doctors.longitude,
            latitude: json.doctors.latitude,
            status: json.doctors.status,
          });
        } else {
          this.setState([]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  Editdoctors = () => {
    this.setState({ isloading: true });
    var form = new FormData();
    form.append('name', this.state.name);
    form.append('house_number', this.state.house_number);
    form.append('street1', this.state.address);
    form.append('street2', this.state.address2);
    form.append('city', this.state.city);
    form.append('state', this.state.state);
    form.append('pincode', this.state.pincode);
    form.append('email', this.state.email);
    form.append('country_code', this.state.country_code_selected);
    form.append('contact', this.state.contact);
    form.append('status', this.state.status);
    form.append('description', this.state.about);
    form.append('latitude', this.state.latitude);
    form.append('longitude', this.state.longitude);
    form.append('experience', this.state.experience);
    form.append('doctor_type', this.state.doctor_type);
    form.append('address', this.state.selected_formatted_address);
    if (this.state.images.length > 0) {
      this.state.images.map((item) => {
        form.append('image', item);
      });
    }

    fetch(global.api_link + 'doctorProfileUpdate/' + this.props.id, {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Profile Upated Succesfully...');
          this.props.navigate('/doctors');
        } else {
          this.setState([]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isloading: false,
        });
      });
  };

  handleChange = (address) => {
    this.setState({ autoaddress: address });
  };

  uploadEditImage = async (e) => {
    this.setState({
      editedImage: true,
      editdoctorImage: e.target.files[0],
    });
  };

  handleSelectAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selected_formatted_address: results[0].formatted_address,
        });
        var address = results[0].address_components;
        address.map((item) => {
          if (item.types[0] == 'street_number') {
            this.setState({ house_number: item.long_name });
          }
          if (item.types[0] == 'route') {
            this.setState({ address: item.long_name });
          }
          if (item.types[0] == 'sublocality') {
            this.setState({ address2: item.long_name });
          }
          if (item.types[0] == 'administrative_area_level_3') {
            this.setState({ city: item.long_name });
          }
          if (item.types[0] == 'administrative_area_level_1') {
            this.setState({ state: item.long_name });
          }
          if (item.types[0] == 'postal_code') {
            this.setState({ pincode: item.long_name });
          }
        });

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ latitude: latLng.lat });
        this.setState({ longitude: latLng.lng });
      })
      .catch((error) => console.error('Error', error));
  };

  handleSelect = (data) => {
    this.setState({ selectedOptions: data });
    var array = [];
    data.map((item) => {
      array.push(item.value);
    });
    this.setState({ selectedDoctors: array });
  };

  uploadImage = async (e) => {
    let image = this.state.images;
    image.push(e.target.files[0]);
    this.setState({ images: image });
  };

  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <>
              <section id="main-content" className="">
                <div className="wrapper main-wrapper row">
                  <div className="col-lg-12">
                    <section className="box">
                      <header className="panel_header">
                        <h2 className="title pull-left">Edit Doctor</h2>
                      </header>
                      <div className="content-body">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.Editdoctors();
                          }}
                        >
                          <div className="row">
                            {/* name */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">Name</label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your name"
                                    className="form-control"
                                    value={this.state.name}
                                    onChange={(e) => {
                                      this.setState({ name: e.target.value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* email */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  placeholder="Enter your email address"
                                  className="form-control"
                                  value={this.state.email}
                                  onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                  }}
                                />
                              </div>
                            </div>
                            {/* contact */}
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      C. Code
                                    </label>
                                    <div className="controls">
                                      <select
                                        className="form-control"
                                        value={this.state.country_code_selected}
                                        onChange={(e) => {
                                          this.setState({
                                            country_code_selected:
                                              e.target.value,
                                          });
                                        }}
                                      >
                                        <option>Choose</option>
                                        <option value="+91">+91</option>
                                        <option value="+93">+93</option>
                                        <option value="+94">+94</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="form-group">
                                    <label className="form-label" for="phone">
                                      Contact Number
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="tel"
                                        maxlength="10"
                                        placeholder="Enter your contact number"
                                        className="form-control"
                                        id="phone"
                                        value={this.state.contact}
                                        onChange={(e) => {
                                          this.setState({
                                            contact: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">Hospital</label>
                                <div className="dropdown-container">
                                  <Select
                                    options={this.state.optionList}
                                    placeholder="Choose Hospitals..."
                                    value={this.state.selectedOptions}
                                    onChange={(e) => {
                                      this.handleSelect(e);
                                    }}
                                    isSearchable={true}
                                    isMulti
                                  />
                                </div>
                              </div>
                            </div>
                            {/* address start*/}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">Location</label>

                                <div className="controls">
                                  <PlacesAutocomplete
                                    value={
                                      this.state.selected_formatted_address
                                    }
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelectAddress}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div>
                                        <input
                                          {...getInputProps({
                                            placeholder:
                                              'Start typing to search places...',
                                            className:
                                              'location-search-input w-100 form-control',
                                          })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => {
                                            const className = suggestion.active
                                              ? 'suggestion-item--active w-100'
                                              : 'suggestion-item w-100';
                                            return (
                                              <div
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  className
                                                )}
                                              >
                                                <span>
                                                  {suggestion.description}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  House Number
                                </label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your House Number"
                                    className="form-control"
                                    value={this.state.house_number}
                                    onChange={(e) => {
                                      this.setState({
                                        house_number: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Street Address 1
                                </label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your Street Address 1"
                                    className="form-control"
                                    value={this.state.address}
                                    onChange={(e) => {
                                      this.setState({
                                        address: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Street Address 2
                                </label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your Street Address 2"
                                    className="form-control"
                                    value={this.state.address2}
                                    onChange={(e) => {
                                      this.setState({
                                        address2: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">City</label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your City"
                                    className="form-control"
                                    value={this.state.city}
                                    onChange={(e) => {
                                      this.setState({ city: e.target.value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">State</label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter your state"
                                    className="form-control"
                                    value={this.state.state}
                                    onChange={(e) => {
                                      this.setState({ state: e.target.value });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">Pincode</label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="tel"
                                    placeholder="Enter your Pincode"
                                    className="form-control"
                                    value={this.state.pincode}
                                    onChange={(e) => {
                                      this.setState({
                                        pincode: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Experience (In Years)
                                </label>
                                <span className="desc" />
                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter Experience"
                                    className="form-control"
                                    value={this.state.experience}
                                    onChange={(e) => {
                                      this.setState({
                                        experience: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* address end */}

                            <div class="col-md-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="pro-start-date"
                                >
                                  Doctor's Specialization
                                </label>
                                <div class="controls">
                                  <select
                                    class="form-control"
                                    aria-label="Default select example"
                                    value={this.state.doctor_type}
                                    onChange={(e) => {
                                      this.setState({
                                        doctor_type: e.target.value,
                                      });
                                    }}
                                  >
                                    <option  value="" disabled selected>
                                      Open this select menu
                                    </option>
                                    {this.state.category.map(
                                      (values, index) => {
                                        return (
                                          <option value={values.id}>
                                            {values.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* about */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Description
                                </label>

                                <div className="controls">
                                  <textarea
                                    className="form-control autogrow"
                                    placeholder="About...."
                                    rows={2}
                                    value={this.state.about}
                                    onChange={(e) => {
                                      this.setState({ about: e.target.value });
                                    }}
                                    data-gramm="false"
                                    data-gramm_editor="false"
                                    data-enable-grammarly="false"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* image */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Profile Image
                                </label>
                                <div className="controls">
                                  {this.state.editedImage ? (
                                    <img
                                      id="target"
                                      src={URL.createObjectURL(
                                        this.state.editdoctorImage
                                      )}
                                      style={{
                                        width: '200px',
                                        height: '200px',
                                      }}
                                    />
                                  ) : this.state.editdoctorImage != null ? (
                                    <img
                                      id="target"
                                      src={
                                        global.img_link +
                                        this.state.editdoctorImage
                                      }
                                      style={{
                                        width: '200px',
                                        height: '200px',
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  <input
                                    type={'file'}
                                    accept=".png, .jpg, .jpeg"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.uploadEditImage(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end">
                              {this.state.isLoading ? (
                                <button className="btn btn-primary mt-10 btn-corner disabled">
                                  <div
                                    class="spinner-border"
                                    role="status"
                                  ></div>
                                  Please wait
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary rounded"
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Editdoctor
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
