import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import user from '../assets/images/selected.png';
import hospital from '../assets/images/hospital.png';
import doctor from '../assets/images/doctor2.png';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: 0,
      hospital: 0,
      doctor: 0,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchDashboard();
  }

  fetchDashboard = () => {
    fetch(global.api_link + 'dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            user: json.Total_User,
            hospital: json.Total_Hospital,
            doctor: json.Total_Doctor,
          });
        } else {
          this.setState({ user: 0, hospital: 0, doctor: 0 });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />

          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className=" ">
              <div className="wrapper main-wrapper row">
                <div className="col-lg-12">
                  <section className="box nobox marginBottom0">
                    <div className="content-body">
                      <div className="row my-4">
                        <div className="col-md-4">
                          <div className="r4_counter db_box">
                            <div class="row d-flex justify-content-center">
                              <div class="col-md-3">
                                <img src={user} className="users" alt="" />
                              </div>
                              <div class="col-md-9">
                                <Link to="/user">
                                  <h5 className="headss">Total Users</h5>
                                  <h3 className="heads1">{this.state.user}</h3>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="r4_counter db_box">
                            <div class="row d-flex justify-content-center">
                              <div class="col-md-3">
                                <img src={hospital} className="users" alt="" />
                              </div>

                              <div class="col-md-9">
                                <Link to="/hospital">
                                  <h5 className="headss">Total Hospitals</h5>
                                  <h3 className="heads1">
                                    {this.state.hospital}
                                  </h3>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="r4_counter db_box">
                            <div class="row d-flex justify-content-center">
                              <div class="col-md-3">
                                <img src={doctor} className="users" alt="" />
                              </div>

                              <div class="col-md-9">
                                <Link to="/doctors ">
                                  <h5 className="headss">Total Doctors</h5>
                                  <h3 className="heads1">
                                    {this.state.doctor}
                                  </h3>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End .row */}
                    </div>
                  </section>
                </div>
                <div className="clearfix" />
                {/* MAIN CONTENT AREA STARTS */}

                <div className="clearfix" />

                <div className="clearfix" />

                <div className="clearfix" />
                {/* MAIN CONTENT AREA ENDS */}
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

export default Home;
