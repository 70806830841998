import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import Loader from '../components/Loader';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export class AddServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      selectedOptions: [],
      category: [],
      rows: [
        {
          service_id: '',
          service_fees: '',
          platform_fees: '',
          user_discount: '',
          status: 'active',
        },
      ],
      loading: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchCategory();
    this.getDoctorServiceProfile();
  }

  fetchCategory = () => {
    fetch(global.api_link + 'getServiceList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.profile,
          });
        } else {
          this.setState({
            category: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  AddServices = () => {
    this.setState({ loading: true });
    fetch(global.api_link + 'addDoctorService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        doctor_services: this.state.rows,
        doctor_id: this.props.id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Services Added Succesfully...');
          this.props.navigate('/doctors');
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getDoctorServiceProfile = () => {
    fetch(global.api_link + 'getDoctorServiceProfile/' + this.props.id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          console.log(json.profile);
          this.setState({
            rows: json.profile,
          });
        } else {
          this.setState([]);
        }
        console.log(this.state);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  addRows = () => {
    const data = [
      {
        service_id: '',
        service_fees: '',
        platform_fees: '',
        user_discount: '',
        status: 'active',
      },
    ];
    this.setState({
      rows: [...this.state.rows, ...data],
    });
  };

  handleSpecificRows = (e) => {
    const rows = [...this.state.rows];
    rows.splice(e, 1);
    this.setState({
      rows: rows,
    });
  };

  handleChange = (index) => (e) => {
    const rows = [...this.state.rows];
    rows[index][e.target.name] = e.target.value;
    this.setState({
      rows: rows,
    });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className="">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />

                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">Add Services</h2>
                      <div className="title pull-right me-4">
                        {this.state.loading ? (
                          <button className="btn btn-primary btn-corner disabled">
                            <div class="spinner-border" role="status"></div>
                            Please wait
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-corner"
                            onClick={() => this.AddServices()}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div class="col-md-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            {this.state.rows.length > 0 && (
                              <table
                                id="tech-companies-1"
                                className="table vm table-small-font no-mb table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th>Sno.</th>
                                    <th>Choose Service</th>
                                    <th>Service Fees</th>
                                    <th>Platform Fees</th>
                                    <th>User Discount (in %)</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rows.map((values, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="service_id"
                                            onChange={this.handleChange(index)}
                                            value={
                                              this.state.rows[index].service_id
                                            }
                                          >
                                            <option value="">
                                              Open this select menu
                                            </option>
                                            {this.state.category.map(
                                              (values, index) => {
                                                return (
                                                  <option
                                                    value={values.id}
                                                    key={index}
                                                  >
                                                    {values.service_name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Service Fees"
                                            className="form-control"
                                            name="service_fees"
                                            value={
                                              this.state.rows[index]
                                                .service_fees
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Platform Fees"
                                            className="form-control"
                                            name="platform_fees"
                                            value={
                                              this.state.rows[index]
                                                .platform_fees
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="User Discount"
                                            className="form-control"
                                            name="user_discount"
                                            value={
                                              this.state.rows[index]
                                                .user_discount
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <select
                                            className="form-control"
                                            value={
                                              this.state.rows[index].status
                                            }
                                            name="status"
                                            onChange={this.handleChange(index)}
                                          >
                                            <option value="active">
                                              Active
                                            </option>
                                            <option value="inactive">
                                              Inactive
                                            </option>
                                          </select>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger btn-corner"
                                            onClick={(idx) =>
                                              this.handleSpecificRows(index)
                                            }
                                          >
                                            X
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                            <div class="d-flex align-item-center justify-content-end w-100 my-4">
                              <button
                                className="btn btn-success btn-corner"
                                onClick={() => this.addRows()}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <AddServices
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
