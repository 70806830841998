import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import star from '../assets/images/star.png';
import cardio from '../assets/images/cardio.png';
import skincare from '../assets/images/skincare.png';
import wavyhair from '../assets/images/wavy-hair.png';
import diabetes from '../assets/images/diabetes.png';
import { useLocation, useNavigate, useParams } from 'react-router';
import Loader from '../components/Loader';
import { Carousel } from 'react-responsive-carousel';

const props = {
  showArrows: true,
  showStatus: false,
  showIndicators: false,
  infiniteLoop: true,
  showThumbs: false,
  autoPlay: true,
  interval: 5000,
  transitionTime: 500,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  stopOnHover: true,
  useKeyboardArrows: true,
};

export class HospitalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital: {},
      name: '',
      email: '',
      address: '',
      contact: '',
      about: '',
      isLoading: true,
      covers: [],
    };
  }

  componentDidMount() {
    this.fetchhospitalsprofile(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.fetchhospitalsprofile(this.props.id);
    }
  };

  fetchhospitalsprofile = (id) => {
    fetch(global.api_link + 'getHospitalProfile/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            name: json.hospital.name,
            email: json.hospital.email,
            address: json.hospital.address,
            contact: json.hospital.contact,
            about: json.hospital.about,
            covers: json.cover_image,
          });
        } else {
          this.setState([]);
        }
        console.log(this.state);
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          {/* SIDEBAR - START */}
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className=" ">
              <div className="wrapper main-wrapper row">
                {/* MAIN CONTENT AREA STARTS */}
                <div className="col-lg-12">
                  <section className="box ">
                    <div className="content-body ">
                      <div className="row">
                        <div className="doctors-list v2  relative">
                          {this.state.covers.length > 0 && (
                            <Carousel {...props}>
                              {this.state.covers.map((values, index) => {
                                return (
                                  <img
                                    src={global.img_link + values.cover_image}
                                    className=""
                                    alt=""
                                    key={index}
                                    style={{
                                      width: '100%',
                                      height: '300px',
                                    }}
                                  />
                                );
                              })}
                            </Carousel>
                          )}
                          <div className="doctors-head relative text-left mb-0">
                            <div className="doc-img img-circle">
                              <div className="stutas" />
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <h1 className="header relative bold">
                                  {this.state.name}
                                </h1>
                              </div>
                              <div className="col-md-4 d-flex justify-content-end">
                                <i class="fa fa-clock-o  ">
                                  <span className="hos_pital me-4">
                                    All-Timing
                                  </span>
                                </i>
                              </div>
                            </div>

                            <h5 className="boldy">
                              {' '}
                              <i className="fas fa-map-marker-alt me-1 " />{' '}
                              {this.state.address}
                            </h5>
                            <h5 className="boldy mt-3">
                              {' '}
                              <i className="	fas fa-phone me-1 " />
                              {this.state.contact}
                            </h5>
                            <h5 className="boldy mt-3">
                              {' '}
                              <i className="fa-solid fa-envelope me-1 " />
                              {this.state.email}
                            </h5>
                            <div className="doc-rating mt-3">
                              <h5 class="boldy">
                                <img src={star} className="star" alt="" />
                                4.5/5.0
                              </h5>
                            </div>
                            <section>
                              <div class="row">
                                <div class="col-md-12 mt-4">
                                  <h5 className="header bold">Services</h5>
                                </div>
                              </div>

                              <div class="row">
                                <div className="page-container row-fluid container-fluid">
                                  <div className="wrapper main-wrapper row mt-0">
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                    <div class="col-md-3 my-2">
                                      <p className="bold m-0 pointers columnsitting specialization">
                                        Cardiology
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-lg-12">
                  <section className="box ">
                    <div className="content-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <h3 className="header relative bold">Description</h3>
                          <p className="boldy mt-3">{this.state.about}</p>
                        </div>
                      </div>{' '}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <HospitalProfile
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
