import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../AuthContextProvider';

export class Login extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isloading: false,
      hidden: true,
    };

    // this.toggleShow = this.toggleShow.bind(this);
  }

  // toggleShow() {
  //   this.setState({ hidden: !this.state.hidden });
  // }

  handleRegistration = (e) => {
    e.preventDefault();
    if (this.state.email === '') {
      toast.error('Email is Required');
      return;
    } else if (this.state.password === '') {
      toast.error('Password is Required');
      return;
    }
    this.setState({ isloading: true });

    fetch(global.api_link + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Login Successful');
          const data = { token: json.token, data: json.data };
          localStorage.setItem('@auth_login', JSON.stringify(data));
          this.context.login(json.data, json.token);

          const path = this.props.location.state?.path || '/home';

          this.props.navigate(path, { replace: true });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isloading: false,
        });
      });
  };

  render() {
    return (
      <div class="backg">
        <div
          className="container-fluid d-flex align-items-center justify-content-center"
          style={{
            minHeight: '100%',
          }}
        >
          <div
            className="login-wrapper row"
            style={{
              minHeight: '100%',
              width: '100%',
            }}
          >
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div id="login">
                <div className="login-form-header">
                  <img
                    src="../data/icons/padlock.png"
                    alt="login-icon"
                    style={{ width: '64px' }}
                  />
                  <div className="login-header">
                    <h4 className="bold color-white">Login Now!</h4>
                    <h4>
                      <small>Please enter your credentials to login.</small>
                    </h4>
                  </div>
                </div>
                <div className="box login">
                  <div className="content-body" style={{ paddingTop: '30px' }}>
                    <form
                      id="msg_validate"
                      action="#"
                      noValidate="novalidate"
                      className="no-mb no-mt"
                    >
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="controls">
                              <input
                                type="text"
                                className="form-control"
                                name="formfield2"
                                placeholder="email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Password</label>

                            <div class="position-relative">
                              <input
                                type={this.state.hidden ? 'password' : 'text'}
                                className="form-control "
                                name="formfield1"
                                placeholder="password"
                                value={this.state.password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                              />
                              <i
                                className={
                                  this.state.hidden
                                    ? 'fas fa-eye position-absolute'
                                    : 'fas fa-eye-slash position-absolute'
                                }
                                onClick={() => {
                                  this.setState({
                                    hidden: !this.state.hidden,
                                  });
                                }}
                                style={{
                                  top: '14px',
                                  right: '6px',
                                }}
                              ></i>
                            </div>
                          </div>
                          <div className="pull-right">
                            {this.state.isloading ? (
                              <button className="btn btn-primary mt-10 btn-corner disabled">
                                <div class="spinner-border" role="status"></div>
                                Please wait
                              </button>
                            ) : (
                              <button
                                onClick={(e) => this.handleRegistration(e)}
                                className="btn btn-primary mt-10 btn-corner"
                              >
                                Log in
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Login {...props} {...useParams()} navigate={abcd} location={location} />
  );
}

export default (props) => <Navigate {...props} />;
