import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

export class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addLoading: false,
      isLoading: true,
      questionList: [],
      open: false,
      openEdit: false,
      editLoading: false,
      add_question_name: '',
      add_question_type: '',
      edit_question_id: '',
      edit_question_name: '',
      edit_question_type: '',
    };
  }

  componentDidMount() {
    this.getQuestionList();
  }

  addQuestion = () => {
    if (this.state.add_question_name == '') {
      toast.error('Please enter question name');
      return false;
    }
    if (this.state.add_question_type == '') {
      toast.error('Please select question type');
      return false;
    }
    this.setState({ addLoading: true });
    fetch(global.api_link + 'addQuestion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        question_name: this.state.add_question_name,
        question_type: this.state.add_question_type,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.getQuestionList();
          this.setState({
            addLoading: false,
            add_question_name: '',
            add_question_type: '',
            open: false,
          });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addLoading: false });
      });
  };

  editQuestion = () => {
    this.setState({ editLoading: true });
    fetch(global.api_link + 'questionUpdate/' + this.state.edit_question_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        question_name: this.state.edit_question_name,
        question_type: this.state.edit_question_type,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.getQuestionList();
          this.setState({
            editLoading: false,
            edit_question_id: '',
            edit_question_name: '',
            edit_question_type: '',
            openEdit: false,
          });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ editLoading: false });
      });
  };

  showAlert = (id) => {
    Swal.fire({
      title: ' Do you want to Delete this Question ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteQuestion/' + id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('Question Deleted Successfully');
              this.getQuestionList();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  getQuestionList = () => {
    fetch(global.api_link + 'getQuestionList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ questionList: json.data });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className="">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />
                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">
                        Questions related to Services
                      </h2>
                      <div className="title pull-right ps-0 pe-3">
                        <button
                          className="btn btn-primary btn-corner"
                          onClick={() =>
                            this.setState({
                              open: true,
                            })
                          }
                        >
                          Add More Question
                        </button>
                      </div>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Sno</th>
                                <th>Question</th>
                                <th>Question Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.questionList.map((values, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{values.question_name}</td>
                                    <td>{values.question_type}</td>
                                    <td>
                                      <div className="d-flex align-items-center gap-2">
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={() => {
                                            this.setState({
                                              openEdit: true,
                                              edit_question_id: values.id,
                                              edit_question_name:
                                                values.question_name,
                                              edit_question_type:
                                                values.question_type,
                                            });
                                          }}
                                        >
                                          <i className="fa-solid fa-pencil" />
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => {
                                            this.showAlert(values.id);
                                          }}
                                        >
                                          <i className="fa-solid fa-trash" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
        {/* add modal */}
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Add Question</h3>
                <div className="form-group">
                  <div className="controls">
                    <label className="form-label mt-2" htmlFor="pro-start-date">
                      Question
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.add_question_name}
                      onChange={(e) => {
                        this.setState({ add_question_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Choose Question Type
                  </label>
                  <div class="controls">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="question_type"
                            value="subjective"
                            id="subjective"
                            className="m-0"
                            checked={
                              this.state.add_question_type === 'subjective'
                            }
                            onChange={(e) => {
                              this.setState({
                                add_question_type: e.target.value,
                              });
                            }}
                          />
                          <label for="subjective" className="m-0">
                            Subjective
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="question_type"
                            value="objective"
                            id="objective"
                            className="m-0"
                            checked={
                              this.state.add_question_type === 'objective'
                            }
                            onChange={(e) => {
                              this.setState({
                                add_question_type: e.target.value,
                              });
                            }}
                          />
                          <label for="objective" className="m-0">
                            Objective
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addLoading ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.addQuestion();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Edit modal */}
        <Modal
          open={this.state.openEdit}
          onClose={() => this.setState({ openEdit: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Edit Question</h3>
                <div className="form-group">
                  <div className="controls">
                    <label className="form-label mt-2" htmlFor="pro-start-date">
                      Question
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.edit_question_name}
                      onChange={(e) => {
                        this.setState({ edit_question_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Choose Question Type
                  </label>
                  <div class="controls">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="question_type"
                            value="subjective"
                            id="subjective"
                            className="m-0"
                            checked={
                              this.state.edit_question_type === 'subjective'
                            }
                            onChange={(e) => {
                              this.setState({
                                edit_question_type: e.target.value,
                              });
                            }}
                          />
                          <label for="subjective" className="m-0">
                            Subjective
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="question_type"
                            value="objective"
                            id="objective"
                            className="m-0"
                            checked={
                              this.state.edit_question_type === 'objective'
                            }
                            onChange={(e) => {
                              this.setState({
                                edit_question_type: e.target.value,
                              });
                            }}
                          />
                          <label for="objective" className="m-0">
                            Objective
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.editLoading ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.editQuestion();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Questions
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
