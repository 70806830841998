import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import Loader from '../components/Loader';
import Select from 'react-select';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export class AddHospitalServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      selectedOptions: [],
      category: [],
      services_fees: '',
      platform_fees: '',
      user_discount: '',
      status: 'active',
      service: '',
      disease: [],
      rows: [],
      loading: false,
      isLoading: true,
      can_negotiate: false,
      breakdownIndex: 0,
      breakdownNegotiationIndex: 0,
    };
  }

  componentDidMount() {
    this.fetchCategory();
    this.fetchDisease();
    this.getHospitalServiceProfile();
    this.addRows();
  }

  fetchCategory = () => {
    fetch(global.api_link + 'getServiceList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.profile,
          });
        } else {
          this.setState({
            category: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  AddHospitalServices = () => {
    this.setState({ loading: true });
    fetch(global.api_link + 'addHospitalService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        hospital_services: this.state.rows,
        hospital_id: this.props.id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Service Added Succesfully...');
          this.props.navigate('/hospital');
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  //remove breakdown row
  removeBreakdownRow = (index) => {
    var rows = this.state.rows;

    rows[this.state.breakdownIndex].breakdown.splice(index, 1);

    this.setState({
      rows: rows,
    });
  };

  getHospitalServiceProfile = () => {
    fetch(global.api_link + 'getHospitalServiceProfile/' + this.props.id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            rows: json.profile,
          });
        } else {
          this.setState([]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  fetchDisease = () => {
    fetch(global.api_link + 'getcategoryList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            disease: json.disease_category,
          });
        } else {
          this.setState({
            category: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleSpecificRows = (e) => {
    const rows = [...this.state.rows];
    rows.splice(e, 1);
    this.setState({
      rows: rows,
    });
  };

  removeBreakdownNegotiationRow = (index) => {
    var rows = this.state.rows;

    rows[this.state.breakdownIndex].breakdown[
      this.state.breakdownNegotiationIndex
    ].negotiable_breakdown.splice(index, 1);

    this.setState({
      rows: rows,
    });
  };


  handleChange = (index) => (e) => {
    const rows = [...this.state.rows];
    rows[index][e.target.name] = e.target.value;
    this.setState({
      rows: rows,
    });
  };

  handleChange2 = (index) => (e) => {
    const rows = [...this.state.rows];
    rows[index][e.target.name] = e.target.value;
    this.setState({
      rows: rows,
    });
  };

  addRows = () => {
    const data = [
      {
        service_id: '',
        disease_id: '',
        service_fees: '',
        platform_fees: '',
        user_discount: '',
        status: 'active',
        breakdown: [],
      },
    ];
    this.setState({
      rows: [...this.state.rows, ...data],
    });
  };

  addBreakdownRow = (index) => {
    var rows = this.state.rows;

    if (rows[index].breakdown == undefined) {
      var breakdown = [];

      rows[index].breakdown = breakdown;
    } else {
      var breakdown = rows[index].breakdown;

      breakdown.push({
        breakdown_item: '',
        breakdown_price: '',
        can_negotiate: false,
        negotiable_breakdown: [],
      });
    }

    this.setState({
      rows: rows,
    });
  };

  addNegotiableBreakdownRow = (index, breakdownIndex) => {
    var rows = this.state.rows;

    if (
      rows[index].breakdown[breakdownIndex].negotiable_breakdown == undefined
    ) {
      var negotiable_breakdown = [];

      rows[index].breakdown[breakdownIndex].negotiable_breakdown =
        negotiable_breakdown;
    } else {
      var negotiable_breakdown =
        rows[index].breakdown[breakdownIndex].negotiable_breakdown;

      negotiable_breakdown.push({
        negotiable_item: '',
        negotiable_price: '',
      });
    }

    this.setState({
      rows: rows,
    });
  };

  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className="">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />

                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">Hospital Services</h2>
                      <div className="title pull-right me-4">
                        {this.state.loading ? (
                          <button className="btn btn-primary btn-corner disabled">
                            <div class="spinner-border" role="status"></div>
                            Please wait
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-corner"
                            onClick={() => this.AddHospitalServices()}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div class="col-md-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            {this.state.rows.length > 0 && (
                              <table
                                id="tech-companies-1"
                                className="table vm table-small-font no-mb table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th>Sno.</th>
                                    <th>Choose Service</th>
                                    <th>Choose Disease</th>
                                    <th>Service Fees</th>
                                    <th>Platform Fees</th>
                                    <th>User Discount(In %)</th>
                                    <th>Status</th>
                                    <th>BreakDown</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rows.map((values, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="service_id"
                                            onChange={this.handleChange(index)}
                                            value={
                                              this.state.rows[index].service_id
                                            }
                                          >
                                            <option value="" disabled>
                                              Open this select menu
                                            </option>
                                            {this.state.category.map(
                                              (values, index) => {
                                                return (
                                                  <option
                                                    value={values.id}
                                                    key={index}
                                                  >
                                                    {values.service_name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </td>
                                        <td>
                                          <select
                                            class="form-control"
                                            aria-label="Default select example"
                                            onChange={this.handleChange2(index)}
                                            value={
                                              this.state.rows[index].disease_id
                                            }
                                            name="disease_id"
                                          >
                                            <option selected disabled>
                                              Open this select menu
                                            </option>
                                            {this.state.disease.map(
                                              (category) => (
                                                <optgroup
                                                  label={category.name}
                                                  key={category.id}
                                                >
                                                  {category.subcategories.map(
                                                    (subcategory, index) => (
                                                      <option
                                                        value={subcategory.id}
                                                        key={index}
                                                      >
                                                        {subcategory.name}
                                                      </option>
                                                    )
                                                  )}
                                                  {!category.subcategories
                                                    .length && (
                                                    <option value={category.id}>
                                                      {category.name}
                                                    </option>
                                                  )}
                                                </optgroup>
                                              )
                                            )}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Service Fees"
                                            className="form-control"
                                            name="service_fees"
                                            value={
                                              this.state.rows[index]
                                                .service_fees
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Platform Fees"
                                            className="form-control"
                                            name="platform_fees"
                                            value={
                                              this.state.rows[index]
                                                .platform_fees
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="User Discount"
                                            className="form-control"
                                            name="user_discount"
                                            value={
                                              this.state.rows[index]
                                                .user_discount
                                            }
                                            onChange={this.handleChange(index)}
                                          />
                                        </td>
                                        <td>
                                          <select
                                            className="form-control"
                                            value={
                                              this.state.rows[index].status
                                            }
                                            name="status"
                                            onChange={this.handleChange(index)}
                                          >
                                            <option value="active">
                                              Active
                                            </option>
                                            <option value="inactive">
                                              Inactive
                                            </option>
                                          </select>
                                        </td>

                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-success btn-corner"
                                            onClick={() => {
                                              this.setState({
                                                open: true,
                                                breakdownIndex: index,
                                              });
                                              // this.addBreakdownRow(index);
                                            }}
                                          >
                                            <span>Show</span>
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger btn-corner"
                                            onClick={(idx) =>
                                              this.handleSpecificRows(index)
                                            }
                                          >
                                            X
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                            <div class="d-flex align-item-center justify-content-end w-100 my-4">
                              <button
                                className="btn btn-success btn-corner"
                                onClick={() => this.addRows()}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>

        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          focusTrapped={false}
        >
          {this.state.rows.length > 0 && (
            <div className="row">
              <div className="col-lg-12">
                <header className="d-flex align-items-center justify-content-between">
                  <h2 className="title pull-left">Services Breakdown</h2>
                  <div className="title pull-right me-4">
                    {this.state.loading ? (
                      <button className="btn btn-primary btn-corner disabled">
                        <div class="spinner-border" role="status"></div>
                        Please wait
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-corner"
                        onClick={() => 
                          this.setState({ open: false })
                        }
                      >
                        Save Breakdown
                      </button>
                    )}
                  </div>
                </header>
                <div className="row">
                  <div class="col-md-12">
                    <div
                      className="table-responsive"
                      data-pattern="priority-columns"
                    >
                      <table
                        id="tech-companies-1"
                        className="table vm table-small-font no-mb table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Sno.</th>
                            <th>BreakDown Item</th>
                            <th>Amount</th>
                            <th>Can Negotiate</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows[this.state.breakdownIndex]
                            .breakdown !== undefined &&
                            this.state.rows[
                              this.state.breakdownIndex
                            ].breakdown.map((values, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput2"
                                      placeholder="BreakDown Item"
                                      value={values.breakdown_item}
                                      onChange={(e) => {
                                        const rows = [...this.state.rows];
                                        rows[
                                          this.state.breakdownIndex
                                        ].breakdown[index].breakdown_item =
                                          e.target.value;
                                        this.setState({
                                          rows: rows,
                                        });
                                      }
                                    }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      placeholder="Amount"
                                      className="form-control"
                                      name="platform_fees"
                                      value={values.breakdown_price}
                                      onChange={(e) => {
                                        const rows = [...this.state.rows];
                                        rows[
                                          this.state.breakdownIndex
                                        ].breakdown[index].breakdown_price =
                                          e.target.value;
                                        this.setState({
                                          rows: rows,
                                        });
                                      }
                                    }
                                    />
                                  </td>
                                  <td>
                                    <div className="form-check d-flex align-items-center">
                                      <input
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault"
                                        value={this.state.can_negotiate}
                                        onChange={(e) => {
                                          this.setState({
                                            breakdownNegotiationIndex: index,
                                            can_negotiate: e.target.checked,
                                          });
                                        }}
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <button
                                      className="btn btn-danger btn-corner"
                                      onClick={(idx) =>
                                        this.removeBreakdownRow(index)
                                        // this.handleSpecificRows(index)
                                      }
                                    >
                                      X
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>

                      <div class="d-flex align-item-center justify-content-end w-100 my-4">
                        <button
                          className="btn btn-success btn-corner"
                          onClick={() =>
                            this.addBreakdownRow(this.state.breakdownIndex)
                          }
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>

        <Modal
          open={this.state.can_negotiate}
          onClose={() => this.setState({ can_negotiate: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <header className="d-flex align-items-center justify-content-between">
                <h2 className="title pull-left">Negotiable Breakdown Items</h2>
                <div className="title pull-right me-4">
                  {this.state.loading ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div class="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-corner"
                      onClick={() => this.setState({ can_negotiate: false })}
                    >
                      Save 
                    </button>
                  )}
                </div>
              </header>
              <div className="row">
                <div class="col-md-12">
                  <div
                    className="table-responsive"
                    data-pattern="priority-columns"
                  >
                    {this.state.rows.length > 0 && (
                      <table
                        id="tech-companies-1"
                        className="table vm table-small-font no-mb table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Sno.</th>
                            <th>Negotiable Item</th>
                            <th>Negotiable Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows[this.state.breakdownIndex]
                            .breakdown !== undefined &&
                            this.state.rows[this.state.breakdownIndex]
                              .breakdown[
                              this.state.breakdownNegotiationIndex
                            ] !== undefined &&
                            this.state.rows[
                              this.state.breakdownIndex
                            ].breakdown[
                              this.state.breakdownNegotiationIndex
                            ].negotiable_breakdown.map((item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput2"
                                      placeholder="BreakDown Item"
                                      value={item.negotiable_item}
                                      onChange={(e) => {
                                        const rows = [...this.state.rows];
                                        rows[
                                          this.state.breakdownIndex
                                        ].breakdown[
                                          this.state.breakdownNegotiationIndex
                                        ].negotiable_breakdown[
                                          index
                                        ].negotiable_item = e.target.value;
                                        this.setState({
                                          rows: rows,
                                        });
                                      }}

                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      placeholder="Amount"
                                      className="form-control"
                                      name="platform_fees"
                                      value={item.negotiable_price}
                                      onChange={(e) => {
                                        const rows = [...this.state.rows];
                                        rows[
                                          this.state.breakdownIndex
                                        ].breakdown[
                                          this.state.breakdownNegotiationIndex
                                        ].negotiable_breakdown[
                                          index
                                        ].negotiable_price = e.target.value;
                                        this.setState({
                                          rows: rows,
                                        });
                                      }}
                                    
                                    />
                                  </td>

                                  <td>
                                    <button
                                      className="btn btn-danger btn-corner"
                                      onClick={(idx) =>
                                       this.removeBreakdownNegotiationRow(index)
                                      }
                                    >
                                      X
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                    <div class="d-flex align-item-center justify-content-end w-100 mt-4">
                      <button
                        className="btn btn-success btn-corner"
                        onClick={(index) =>
                          this.addNegotiableBreakdownRow(
                            this.state.breakdownIndex,
                            this.state.breakdownNegotiationIndex
                          )
                        }
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <AddHospitalServices
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
