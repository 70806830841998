import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const Header = () => {
  return (
    <div className="page-topbar">
      <div className="quick-area">
        <div className="pull-left">
          <img
            src={logo}
            style={{
              width: '100%',
              height: '50px',
            }}
          />
        </div>
        <div className="pull-right">
          <ul className="info-menu right-links list-inline list-unstyled">
            <li className="profile showopacity">
              <a href="#" data-toggle="dropdown" className="toggle">
                <img
                  src="../data/profile/profile.jpg"
                  alt="user-image"
                  className="img-circle img-inline"
                />
                <span>
                  Admin <i className="fa fa-angle-down" />
                </span>
              </a>
              <ul className="dropdown-menu profile animated fadeIn">
                {/* <li>
                  <a>
                    <i className="fa fa-user" /> Profile
                  </a>
                </li> */}

                <li className="">
                  <a
                    onClick={() => {
                      localStorage.removeItem('@auth_login');
                      window.location.href = '/';
                    }}
                  >
                    <i className="fa fa-lock" /> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
