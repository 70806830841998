import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
const Sidenav = () => {
  return (
    <div className="page-sidebar fixedscroll">
      <div className="page-sidebar-wrapper" id="main-menu-wrapper">
        <Sidebar>
          <Menu>
            <MenuItem
              icon={<i className="fa fa-home" />}
              component={<NavLink to="/home" />}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<i className="fa fa-users" />}
              component={<Link to="/user" />}
            >
              Users
            </MenuItem>
            <SubMenu label="Setup" icon={<i className="fa fa-cog" />}>
              <MenuItem component={<Link to="/disease" />}>Disease</MenuItem>
              <MenuItem component={<Link to="/subcategory" />}>
                Sub-Categories
              </MenuItem>
              <MenuItem component={<Link to="/questions" />}>
                Questions
              </MenuItem>
              <MenuItem component={<Link to="/addservice" />}>
                Services
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={<i className="fa fa-hospital" />}
              component={<Link to="/hospital" />}
            >
              Hospital
            </MenuItem>
            <MenuItem
              icon={<i className="fa fa-user" />}
              component={<Link to="/doctors" />}
            >
              Doctors
            </MenuItem>
            <MenuItem
              icon={<i className="fa fa-book" />}
              component={<Link to="/bookings" />}
            >
              Bookings
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};

export default Sidenav;
