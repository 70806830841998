import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import star from '../assets/images/star.png';
import clinic from '../assets/images/abc.png';
import { useLocation, useNavigate, useParams } from 'react-router';
import Loader from '../components/Loader';

export class Doctorprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: {},
      name: '',
      category: '',
      hospital_name: '',
      experience: '',
      address: '',
      fees: '',
      description: '',
      isLoading: true,
      profile_img: '',
    };
  }

  componentDidMount() {
    this.fetchdoctorsprofile(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.fetchdoctorsprofile(this.props.id);
    }
  };

  fetchdoctorsprofile = (id) => {
    fetch(global.api_link + 'getDoctorProfile/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            name: json.doctors.name,
            category: json.doctors.category,
            hospital_name: json.doctors.hospital_name,
            experience: json.doctors.experience,
            address: json.doctors.address,
            fees: json.doctors.fees,
            description: json.doctors.description,
            profile_img: json.doctors.profile_image,
          });
        } else {
          this.setState([]);
        }
        console.log(this.state);
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <>
              <section id="main-content" className=" ">
                <div className="wrapper main-wrapper row">
                  <div className="clearfix" />

                  <div class="row">
                    <div className="col-lg-12">
                      <section className="box ">
                        <div className="content-body p">
                          <div className="row">
                            <div className="doctors-list v2  relative">
                              <div className="doctors-head relative text-left mb-0 d-flex align-items-start flex-row">
                                <div className="doc-img img-circle mb-0">
                                  <img
                                    src={
                                      global.img_link + this.state.profile_img
                                    }
                                    alt="image"
                                    style={{
                                      height: '150px',
                                      width: '150px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    marginLeft: '50px',
                                  }}
                                >
                                  <h3 className="header relative bold">
                                    {this.state.name}
                                  </h3>
                                  <h5 className="boldy">Contact : {}</h5>
                                  <h5 className="boldy">
                                    Specialization : {this.state.category}
                                  </h5>
                                  <h5 className="boldy">
                                    {this.state.experience}{' '}
                                    <span>years of Experience</span>
                                  </h5>
                                  <h5 className="boldy">
                                    {this.state.hospital_name}
                                  </h5>
                                  <div className="doc-rating ">
                                    <h5 class="boldy">
                                      <img src={star} className="star" alt="" />
                                      4.5/5.0(101 patient stories)
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <section className="box ">
                      <div className="content-body p">
                        <div className="row">
                          <div className="doctors-list relative">
                            <div className="doctors-head text-center">
                              <h3 className="header  relative bold">
                                Patient stories
                              </h3>
                              <p className="desc relative mb-3">
                                These stories represent patient opinions and
                                experiences. They do not reflect doctor's
                                medical capabilities.
                              </p>
                            </div>
                            <div className="doctor-card has-shadow">
                              <div className="doc-info-wrap">
                                <div className="doctor-img">
                                  <img src="../data/hos-dash/doc1.jpg" alt="" />
                                </div>
                                <div className="doc-info">
                                  <h4 className="bold">Rahul Sharma</h4>
                                  <h5>a month ago</h5>
                                  <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Fugiat cum distinctio,
                                    harum iure eius sequi odit delectus suscipit
                                    nulla labore!
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="doctor-card has-shadow">
                              <div className="doc-info-wrap">
                                <div className="doctor-img">
                                  <img src="../data/hos-dash/doc1.jpg" alt="" />
                                </div>
                                <div className="doc-info">
                                  <h4 className="bold">Aman Rajvanshi</h4>
                                  <h5>a month ago</h5>
                                  <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Fugiat cum distinctio,
                                    harum iure eius sequi odit delectus suscipit
                                    nulla labore!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="col-lg-12">
                    <section className="box ">
                      <div className="content-body">
                        <div className="row">
                          <div className="col-xs-12">
                            <h3 className="header relative bold">
                              Clinic Details
                            </h3>
                            <h4 className="header relative bold mt-3">
                              {this.state.hospital_name}
                            </h4>

                            <div className="doc-rating ">
                              <h5 class="boldy">
                                <img src={star} className="star" alt="" />
                                4.5
                              </h5>
                            </div>
                            <h5 className="boldy">{this.state.address}</h5>
                            <h5 className="boldy">
                              ₹ {this.state.fees} In-clinic Fees
                            </h5>
                          </div>
                        </div>{' '}
                        <hr class="hr" />
                        <div class="row">
                          <div class="col-xs-12">
                            <h3 className="header relative bold">Timings</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2">
                            <h4 className="header relative bold mt-3">
                              Wednesday
                            </h4>
                            <h4 className="header relative bold mt-3">
                              04:00 PM - 06:00 PM
                            </h4>
                          </div>
                          <div class="col-md-2">
                            <h4 className="header relative bold mt-3">
                              Thursday
                            </h4>
                            <h4 className="header relative bold mt-3">
                              Closed
                            </h4>
                          </div>
                        </div>
                        {/* End .row */}
                      </div>
                    </section>
                  </div>

                  <div class="col-lg-12">
                    <section className="box ">
                      <div className="content-body">
                        <div className="row">
                          <div className="col-xs-12">
                            <h3 className="header relative bold">
                              More about Dr. {this.state.name}
                            </h3>
                            <p className="boldy mt-3">
                              {this.state.description}
                            </p>
                          </div>
                        </div>{' '}
                      </div>
                    </section>
                  </div>

                  <div className="clearfix" />
                </div>
              </section>
            </>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Doctorprofile
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
