import React from 'react';
import { Dna } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: '100vh',
        marginLeft: '210px',
      }}
    >
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </div>
  );
};

export default Loader;
