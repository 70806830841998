import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

export class Subcategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      open: false,
      openEdit: false,
      subCategory: [],
      category: [],
      loading: true,
      addSubCategoryName: '',
      addSubCategoryImage: [],
      optionList: [],
      addButtonLoader: false,
      selectedOptionsAdd: '',
      editSubcategoryName: '',
      editSubcategoryId: '',
      editSubcategoryParentId: '',
      editSubcategoryImage: '',
      editedImage: false,
    };
  }

  componentDidMount() {
    this.fetchSubCategory();
    this.fetchCategory();
  }

  fetchSubCategory = () => {
    fetch(global.api_link + 'get_subcategory', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            subCategory: json.data,
            isloading: false,
          });
        } else {
          this.setState({
            subCategory: [],
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally();
  };

  deleteSubCategory = (e) => {
    Swal.fire({
      title: ' Do you want to Delete this Sub Category? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteSubCategory/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success(' Deleted Successfully');
              this.fetchSubCategory();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  uploadImage = async (e) => {
    let image = this.state.addSubCategoryImage;
    image.push(e.target.files[0]);
    this.setState({
      addSubCategoryImage: image,
    });
  };

  uploadEditImage = async (e) => {
    this.setState({
      editedImage: true,
      editSubcategoryImage: e.target.files[0],
    });
  };

  addSubCategory = () => {
    var form = new FormData();
    form.append('name', this.state.addSubCategoryName);
    form.append('parent_id', this.state.selectedOptionsAdd);
    form.append('status', 'active');
    if (this.state.addSubCategoryImage.length > 0) {
      this.state.addSubCategoryImage.map((item) => {
        form.append('image', item);
      });
    }
    this.setState({ addButtonLoader: true });
    fetch(global.api_link + 'addSubCategory', {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Subcategory added Successfully!');
          this.setState({
            open: false,
            addSubCategoryName: '',
            selectedOptionsAdd: '',
            addSubCategoryImage: [],
          });
          this.fetchSubCategory();
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addButtonLoader: false });
      });
  };

  fetchCategory = () => {
    fetch(global.api_link + 'getcategoryList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.disease_category,
          });
        } else {
          this.setState({
            category: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(
        this.setState({
          loading: false,
        })
      );
  };

  editSubCategory = () => {
    var form = new FormData();
    form.append('name', this.state.editSubcategoryName);
    form.append('parent_id', this.state.editSubcategoryParentId);
    form.append('status', 'active');
    if (this.state.editedImage) {
      form.append('image', this.state.editSubcategoryImage);
    }
    this.setState({ addButtonLoader: true });
    fetch(
      global.api_link +
        'subcategoryProfileUpdate/' +
        this.state.editSubcategoryId,
      {
        method: 'POST',
        body: form,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Subcategory Edited Successfully!');
          this.setState({
            openEdit: false,
            editSubcategoryName: '',
            editSubcategoryId: '',
            editSubcategoryImage: '',
            editSubcategoryParentId: '',
            editedImage: false,
          });
          this.fetchSubCategory();
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addButtonLoader: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isloading ? (
            <Loader />
          ) : (
            <section id="main-content" className=" ">
              <div className="wrapper main-wrapper row">
                <div class="col-lg-12 d-flex  justify-content-end">
                  <button
                    type="button"
                    className="btn btn-success btn-icon btn-lg mt-10"
                    onClick={() => this.setState({ open: true })}
                  >
                    <span>Add subcategory</span>
                  </button>
                </div>
                <div className="col-lg-12">
                  <section className="box ">
                    <div className="content-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table vm table-small-font no-mb table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Sno</th>
                                  <th>Image</th>
                                  <th>Subcategory Name</th>
                                  <th>Category Name</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.subCategory.map((values, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={global.img_link + values.image}
                                          style={{
                                            height: '40px',
                                            width: '40px',
                                          }}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {values.name}
                                      </td>
                                      <td
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {values.parent.name}
                                      </td>

                                      <td>
                                        <div className="d-flex align-items-center gap-2">
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                              this.setState({
                                                openEdit: true,
                                                editSubcategoryName:
                                                  values.name,
                                                editSubcategoryId: values.id,
                                                editSubcategoryParentId:
                                                  values.parent_id,
                                                editSubcategoryImage:
                                                  values.image,
                                              });
                                            }}
                                          >
                                            <i className="fa-solid fa-pencil" />
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              this.deleteSubCategory(values.id);
                                            }}
                                          >
                                            <i className="fa-solid fa-trash" />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>

        {/* edit subCategory */}
        <Modal
          open={this.state.openEdit}
          onClose={() => this.setState({ openEdit: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Edit Sub-Category</h3>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Name
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.editSubcategoryName}
                      onChange={(e) => {
                        this.setState({ editSubcategoryName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Choose Category
                  </label>
                  <div class="controls">
                    <select
                      class="form-control"
                      aria-label="Default select example"
                      value={this.state.editSubcategoryParentId}
                      onChange={(e) => {
                        this.setState({
                          editSubcategoryParentId: e.target.value,
                        });
                      }}
                    >
                      <option selected>Open this select menu</option>
                      {this.state.category.map((values, index) => {
                        return <option value={values.id}>{values.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Subcategory Image</label>
                  <div className="controls">
                    {this.state.editedImage ? (
                      <img
                        id="target"
                        src={URL.createObjectURL(
                          this.state.editSubcategoryImage
                        )}
                        style={{
                          width: '200px',
                          height: '200px',
                        }}
                      />
                    ) : this.state.editSubcategoryImage != null ? (
                      <img
                        id="target"
                        src={global.img_link + this.state.editSubcategoryImage}
                        style={{
                          width: '200px',
                          height: '200px',
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <input
                      type={'file'}
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      onChange={(e) => {
                        this.uploadEditImage(e);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addButtonLoader ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.editSubCategory();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* //add Subcategory// */}
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Add Sub-Category</h3>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Name
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.addSubCategoryName}
                      onChange={(e) => {
                        this.setState({ addSubCategoryName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pro-start-date">
                    Choose Category
                  </label>
                  <div class="controls">
                    <select
                      class="form-control"
                      aria-label="Default select example"
                      value={this.state.selectedOptionsAdd}
                      onChange={(e) => {
                        this.setState({ selectedOptionsAdd: e.target.value });
                      }}
                    >
                      <option  value="" disabled selected>Open this select menu</option>
                      {this.state.category.map((values, index) => {
                        return <option value={values.id}>{values.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Subcategory Image</label>
                  <div className="controls">
                    {this.state.addSubCategoryImage.length > 0 &&
                      this.state.addSubCategoryImage[
                        this.state.addSubCategoryImage.length - 1
                      ] && (
                        <img
                          src={URL.createObjectURL(
                            this.state.addSubCategoryImage[
                              this.state.addSubCategoryImage.length - 1
                            ]
                          )}
                          alt="Disease Image"
                          className="img-fluid"
                          style={{
                            width: '200px',
                            height: '200px',
                          }}
                        />
                      )}
                    <input
                      type={'file'}
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      onChange={(e) => {
                        this.uploadImage(e);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addButtonLoader ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.addSubCategory();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Subcategory;
