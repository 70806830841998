import React, { Component, useState } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { toast } from 'react-toastify';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

class Addhospital extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      name: '',
      email: '',
      country_code_selected: '',
      contact: '',
      autoaddress: '',
      house_number: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      status: 'active',
      images: [],
      desc: '',
      latitude: '',
      longitude: '',
      selected_formatted_address: '',
      isLoading: false,
      isNewButtonLoading: false,
      house_number: '',
      covers: [],
      coversVisible: false,
      hospital: {},
      buttonLoading: false,
      country_codes: [],
      doctor_type: '',
    };
    this.messagesEndRef = React.createRef();
  }

  componentDidMount() {
    // this.fetch_country_codes();
  }

  fetch_country_codes = () => {
    fetch(global.api_link + 'get_country_code', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ country_codes: json.data });
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  scrollTobottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  handleChange = (address) => {
    this.setState({ selected_formatted_address: address });
  };

  handleSelectAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selected_formatted_address: results[0].formatted_address,
        });
        var address = results[0].address_components;
        address.map((item) => {
          if (item.types[0] == 'street_number') {
            this.setState({ house_number: item.long_name });
          }
          if (item.types[0] == 'route') {
            this.setState({ address: item.long_name });
          }
          if (item.types[0] == 'political') {
            this.setState({ address2: item.long_name });
          }
          if (item.types[0] == 'administrative_area_level_3') {
            this.setState({ city: item.long_name });
          }
          if (item.types[0] == 'administrative_area_level_1') {
            this.setState({ state: item.long_name });
          }
          if (item.types[0] == 'postal_code') {
            this.setState({ pincode: item.long_name });
          }
          if (item.types[0] == 'country') {
            this.setState({ country: item.long_name });
          }
        });

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ latitude: latLng.lat });
        this.setState({ longitude: latLng.lng });
      })
      .catch((error) => console.error('Error', error));
  };

  uploadImage = async (e) => {
    let image = this.state.images;
    image.push(e.target.files[0]);
    this.setState({ images: image });
  };

  uploadCovers = async (e) => {
    this.setState({ covers: [...this.state.covers, ...e.target.files] });
  };

  deleteImage = (index) => {
    var image = this.state.covers;
    image.splice(index, 1);
    this.setState({
      covers: image,
    });
  };

  addDoctor = () => {
    this.setState({
      buttonLoading: true,
    });
    var form = new FormData();
    form.append('name', this.state.name);
    form.append('house_number', this.state.house_number);
    form.append('street1', this.state.address);
    form.append('street2', this.state.address2);
    form.append('address', this.state.selected_formatted_address);
    form.append('city', this.state.city);
    form.append('state', this.state.state);
    form.append('pincode', this.state.pincode);
    form.append('country', this.state.country);
    form.append('doctor_type', this.state.doctor_type);
    form.append('email', this.state.email);
    form.append('country_code', this.state.country_code_selected);
    form.append('contact', this.state.contact);
    form.append('status', this.state.status);
    form.append('about', this.state.desc);
    form.append('latitude', this.state.latitude);
    form.append('longitude', this.state.longitude);
    if (this.state.images.length > 0) {
      this.state.images.map((item) => {
        form.append('image', item);
      });
    }

    fetch(global.api_link + 'addHospital', {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Hospital Added Successfully');
          this.setState({
            coversVisible: true,
            hospital: json.hospital,
          });
          this.setState({
            buttonLoading: false,
          });
          this.scrollTobottom();
        } else {
          toast.error(json.errors[0]);
          this.setState({
            buttonLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally();
  };

  addHospitalCoverImageStore = () => {
    this.setState({
      isNewButtonLoading: true,
    });
    var form = new FormData();
    if (this.state.covers.length > 0) {
      this.state.covers.map((item) => {
        form.append('hospital_covers[]', item);
      });
    }
    form.append('hospital_id', this.state.hospital.id);
    fetch(global.api_link + 'addHospitalCoverImageStore', {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Cover Image Added Successfully');
          this.props.navigate('/addhospitalservices/' + this.state.hospital.id);
          this.setState({
            isNewButtonLoading: false,
          });
        } else {
          toast.error(json.errors[0]);
          this.setState({
            isNewButtonLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally();
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          <section id="main-content" className="">
            <div className="wrapper main-wrapper row">
              <div className="col-lg-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title pull-left">Add Hospital </h2>
                  </header>
                  <div className="content-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.addDoctor();
                      }}
                    >
                      <div className="row">
                        {/* name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Name</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your name"
                                className="form-control"
                                value={this.state.name}
                                onChange={(e) => {
                                  this.setState({ name: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* email */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="email"
                                placeholder="Enter your email address"
                                className="form-control"
                                value={this.state.email}
                                onChange={(e) => {
                                  this.setState({ email: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* contact */}
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="form-label">C. Code</label>
                                <div className="controls">
                                  <select
                                    className="form-control"
                                    value={this.state.country_code_selected}
                                    onChange={(e) => {
                                      this.setState({
                                        country_code_selected: e.target.value,
                                      });
                                    }}
                                  >
                                    <option>Choose</option>
                                    {this.state.country_codes.map(
                                      (values, index) => {
                                        return (
                                          <option
                                            value={values.dialing_code}
                                            key={index}
                                          >
                                            {values.country_name}
                                            {values.dialing_code}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <label className="form-label" for="phone">
                                  Contact Number
                                </label>

                                <div className="controls">
                                  <input
                                    type="tel"
                                    maxlength="10"
                                    placeholder="Enter your contact number"
                                    className="form-control"
                                    id="phone"
                                    value={this.state.contact}
                                    onChange={(e) => {
                                      this.setState({
                                        contact: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* address start*/}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Location</label>

                            <div className="controls">
                              <PlacesAutocomplete
                                value={this.state.selected_formatted_address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelectAddress}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder:
                                          'Start typing to search places...',
                                        className:
                                          'location-search-input w-100 form-control',
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active w-100'
                                          : 'suggestion-item w-100';
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              className
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">House Number</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your House Number"
                                className="form-control"
                                value={this.state.house_number}
                                onChange={(e) => {
                                  this.setState({
                                    house_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Street Address 1
                            </label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your Street Address 1"
                                className="form-control"
                                value={this.state.address}
                                onChange={(e) => {
                                  this.setState({ address: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Street Address 2
                            </label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your Street Address 2"
                                className="form-control"
                                value={this.state.address2}
                                onChange={(e) => {
                                  this.setState({ address2: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">City</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your City"
                                className="form-control"
                                value={this.state.city}
                                onChange={(e) => {
                                  this.setState({ city: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">State</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your state"
                                className="form-control"
                                value={this.state.state}
                                onChange={(e) => {
                                  this.setState({ state: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Pincode</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="number"
                                placeholder="Enter your Pincode"
                                className="form-control"
                                value={this.state.pincode}
                                onChange={(e) => {
                                  this.setState({ pincode: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Country</label>
                            <span className="desc" />
                            <div className="controls">
                              <input
                                type="text"
                                placeholder="Enter your Country"
                                className="form-control"
                                value={this.state.country}
                                onChange={(e) => {
                                  this.setState({ country: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* address end */}
                        {/* about */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Description</label>

                            <div className="controls">
                              <textarea
                                className="form-control autogrow"
                                placeholder="About...."
                                rows={2}
                                onChange={(e) => {
                                  this.setState({ desc: e.target.value });
                                }}
                                value={this.state.desc}
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                              />
                            </div>
                          </div>
                        </div>
                        {/* image */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Profile Image</label>
                            <div className="controls">
                              {this.state.images.length > 0 &&
                                this.state.images[
                                  this.state.images.length - 1
                                ] && (
                                  <img
                                    src={URL.createObjectURL(
                                      this.state.images[
                                        this.state.images.length - 1
                                      ]
                                    )}
                                    alt="profile"
                                    className="img-fluid"
                                    style={{
                                      width: '200px',
                                      height: '200px',
                                    }}
                                  />
                                )}
                              <input
                                type={'file'}
                                accept=".png, .jpg, .jpeg"
                                className="form-control"
                                onChange={(e) => {
                                  this.uploadImage(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 d-flex justify-content-end">
                          {this.state.buttonLoading ? (
                            <button className="btn btn-primary mt-10 btn-corner disabled">
                              <div class="spinner-border" role="status"></div>
                              Please wait
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary rounded"
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>
            {this.state.coversVisible && (
              <div
                className="wrapper main-wrapper row mt-0"
                ref={this.messagesEndRef}
              >
                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 className="title pull-left">Add Hospital Covers</h2>
                    </header>
                    <div className="content-body">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.addHospitalCoverImageStore();
                        }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-label">Cover Images</label>
                              <div className="controls">
                                {this.state.covers.length > 0 ? (
                                  this.state.covers.map((item, index) => {
                                    return (
                                      <div className="col-md-2">
                                        <img
                                          id="target"
                                          src={URL.createObjectURL(item)}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                            marginRight: 10,
                                          }}
                                        />
                                        <p
                                          onClick={() => {
                                            this.deleteImage(index);
                                          }}
                                          style={{
                                            color: 'red',
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                          }}
                                        >
                                          Delete
                                        </p>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                                <input
                                  type={'file'}
                                  accept=".png, .jpg, .jpeg"
                                  className="form-control"
                                  multiple
                                  onChange={(e) => {
                                    this.uploadCovers(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 d-flex justify-content-end">
                            {this.state.isNewButtonLoading ? (
                              <button className="btn btn-primary mt-10 btn-corner disabled">
                                <div class="spinner-border" role="status"></div>
                                Please wait
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary rounded"
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </div>
            )}
          </section>
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Addhospital
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
