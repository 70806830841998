import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { useLocation, useNavigate, useParams } from 'react-router';
import moment from 'moment';
import Loader from '../components/Loader';

class Userprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {},
      id: '',
      name: '',
      dob: moment(new Date()).format('YYYY-MM-DD'),
      gender: '',
      contact: '',
      email: '',
      profile_img: '',
      weight: '',
      weight_type: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchuserprofile(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.fetchuserprofile(this.props.id);
    }
  };

  fetchuserprofile = (id) => {
    fetch(global.api_link + 'getUserProfile/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            name: json.users.name,
            dob: moment(json.users.dob).format('YYYY-MM-DD'),
            contact: json.users.contact,
            gender: json.users.gender,
            weight: json.users.weight,
            weight_type: json.users.weight_type,
            profile_img: json.users.profile_picture,
            email: json.users.email,
          });
        } else {
          this.setState([]);
        }
        console.log(this.state);
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content" className=" ">
              <div className="wrapper main-wrapper row">
                <div className="clearfix" />
                {/* MAIN CONTENT AREA STARTS */}
                <div className="col-lg-12">
                  <section className="box ">
                    <div className="content-body p">
                      <div className="row">
                        <div className="doctors-list patient relative">
                          <div className="doctors-head relative text-center">
                            <div className="patient-img img-circle">
                              {this.state.profile_img === null ? (
                                <img
                                  src="../data/profile/profile.jpg"
                                  className="rad-50 center-block"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={global.img_link + this.state.profile_img}
                                  className="rad-50 center-block"
                                  alt="img"
                                  style={{
                                    width: '115px',
                                    height: '115px',
                                  }}
                                />
                              )}
                            </div>
                            <h3 className="header w-text relative bold pb-4">
                              {this.state.name}
                            </h3>
                          </div>
                          <div className="row">
                            <div className="patients-info relative">
                              <div className="col-sm-4 col-xs-12">
                                <div className="patient-card has-shadow2">
                                  <div className="doc-info-wrap">
                                    <div className="patient-info">
                                      <h6>Contact</h6>
                                      <h5 className="bold">
                                        {this.state.contact}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="patient-card has-shadow2">
                                  <div className="doc-info-wrap">
                                    <div className="patient-info">
                                      <h6>Date Of Birth</h6>
                                      <h5 className="bold">
                                        {moment(this.state.dob).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="patient-card has-shadow2">
                                  <div className="doc-info-wrap">
                                    <div className="patient-info">
                                      <h6>Email</h6>
                                      <h5 className="bold">
                                        {this.state.email}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-xs-12">
                                <div className="patient-card has-shadow2">
                                  <div className="doc-info-wrap">
                                    <div className="patient-info">
                                      <h6>Weight</h6>
                                      <h5 className="bold">
                                        {this.state.weight}{' '}
                                        {this.state.weight_type}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-xs-12">
                                <div className="patient-card has-shadow2">
                                  <div className="doc-info-wrap">
                                    <div className="patient-info">
                                      <h6>Gender</h6>
                                      <h5 className="bold text-capitalize">
                                        {this.state.gender}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end row */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="row">
                  <h1>My Appointement</h1>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Userprofile
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
