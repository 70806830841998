import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import star from '../assets/images/star.png';
import { useLocation, useNavigate, useParams } from 'react-router';
import Loader from '../components/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';

export class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: [],
      isLoading: true,
      buttonLoader: false,
    };
  }

  componentDidMount() {
    this.fetch_booking_single_admin();
  }

  fetch_booking_single_admin = () => {
    fetch(global.api_link + 'fetch_booking_single_admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        booking_id: this.props.id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            booking: json.booking[0],
          });
        } else {
          this.setState([]);
        }
        console.log(this.state);
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  update_booking_status = (status) => {
    this.setState({
      buttonLoader: true,
    });
    fetch(global.api_link + 'update_booking_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        booking_id: this.props.id,
        status: status,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message);
          this.fetch_booking_single_admin();
        } else {
          toast.error(json.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        this.setState({
          buttonLoader: false,
        });
      });
  };

  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <>
              <section id="main-content">
                <div className="wrapper main-wrapper row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <h3>
                          Booking ID : {this.state.booking.booking_id}
                          <span
                            className="ms-2 text-muted"
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            (Booking status:{' '}
                            <span
                              style={
                                this.state.booking.status === 'cancel'
                                  ? {
                                      color: '#cc0000',
                                    }
                                  : this.state.booking.status === 'pending'
                                  ? {
                                      color: '#1b98b7',
                                    }
                                  : {
                                      color: '#20ad00',
                                    }
                              }
                            >
                              {this.state.booking.status === 'pending'
                                ? 'Pending'
                                : this.state.booking.status === 'confirm'
                                ? 'Accepted'
                                : 'Cancelled'}
                            </span>
                            )
                          </span>
                        </h3>
                        <h4>
                          Date Of Booking :{' '}
                          {this.state.booking.appointment_date}
                        </h4>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {this.state.buttonLoader ? (
                          <button className="btn btn-primary btn-corner disabled">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        ) : this.state.booking.status === 'pending' ? (
                          <>
                            <button
                              className="btn btn-primary rounded mx-2"
                              onClick={() => {
                                this.update_booking_status('confirm');
                              }}
                            >
                              Accept Booking
                            </button>
                            <button
                              className="btn btn-danger rounded"
                              onClick={() => {
                                this.update_booking_status('cancel');
                              }}
                            >
                              Cancel Booking
                            </button>
                          </>
                        ) : this.state.booking.status === 'confirm' ? (
                          <button
                            className="btn btn-danger rounded"
                            onClick={() => {
                              this.update_booking_status('cancel');
                            }}
                          >
                            Cancel Booking
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <section className="box ">
                      <header className="panel_header">
                        <h2 className="title pull-left m-0">User Profile</h2>
                      </header>
                      <div className="content-body">
                        <div className="row">
                          <div className="doctors-list v2 p-0 ps-4">
                            <div className="doctors-head relative text-left mb-0 d-flex align-items-start flex-row">
                              <div className="doc-img img-circle mb-0">
                                <img
                                  src={
                                    global.img_link +
                                    this.state.booking.user.profile_picture
                                  }
                                  alt="image"
                                  style={{
                                    height: '150px',
                                    width: '150px',
                                    borderRadius: '50%',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: '50px',
                                }}
                              >
                                <h3 className="header bold">
                                  {this.state.booking.user.name}
                                </h3>
                                <h5 className="boldy">
                                  Contact : {this.state.booking.user.contact}
                                </h5>

                                <h5 className="boldy">
                                  Date Of Birth :{this.state.booking.user.dob}
                                </h5>
                                <h5 className="boldy">
                                  Email : {this.state.booking.user.email}
                                </h5>
                                <h5 className="boldy">
                                  Weight : {this.state.booking.user.weight}{' '}
                                  {this.state.booking.user.weight_type}
                                </h5>
                                <h5 className="boldy">
                                  Gender : {this.state.booking.user.gender}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="col-lg-6">
                    <section className="box ">
                      <header className="panel_header">
                        <h2 className="title pull-left m-0">
                          Doctor / Hospital Details
                        </h2>
                      </header>
                      <div className="content-body">
                        <div className="row">
                          <div className="doctors-list v2 p-0 ps-4">
                            <div className="doctors-head relative text-left mb-0 d-flex align-items-start flex-row">
                              <div className="doc-img img-circle mb-0">
                                <img
                                  src={
                                    global.img_link +
                                    this.state.booking.hospital.image
                                  }
                                  alt="image"
                                  style={{
                                    height: '150px',
                                    width: '150px',
                                    borderRadius: '50%',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: '50px',
                                }}
                              >
                                <h3 className="header bold">
                                  {this.state.booking.hospital.name}
                                </h3>
                                <h5 className="boldy">
                                  Address :{' '}
                                  {this.state.booking.hospital.house_number},{' '}
                                  {this.state.booking.hospital.street1},{' '}
                                  {this.state.booking.hospital.street2},{' '}
                                  {this.state.booking.hospital.city},{' '}
                                  {this.state.booking.hospital.state},{' '}
                                  {this.state.booking.hospital.pincode},{' '}
                                  {this.state.booking.hospital.country}.
                                </h5>
                                <h5 className="boldy">
                                  Email : {this.state.booking.hospital.email}
                                </h5>
                                <h5 className="boldy">
                                  Contact :{' '}
                                  {this.state.booking.hospital.contact}
                                </h5>
                                <h5 className="boldy">
                                  About : {this.state.booking.hospital.about}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {this.state.booking.questions.length > 0 && (
                    <div className="col-lg-12">
                      <section className="box ">
                        <header className="panel_header">
                          <h2 className="title pull-left m-0">Questions</h2>
                        </header>
                        <div className="content-body">
                          <div className="row">
                            <div className="col-md-12">
                              {this.state.booking.questions.map(
                                (values, index) => {
                                  return (
                                    <div className="form-group">
                                      <label className="form-label">
                                        Q{index + 1}. {values.question_name}
                                      </label>
                                      <div className="controls">
                                        {values.question_answer}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                </div>
              </section>
            </>
          )}
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <BookingDetails
      {...props}
      {...useParams()}
      navigate={abcd}
      location={location}
    />
  );
}

export default (props) => <Navigate {...props} />;
