import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export class Adduser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      dob: moment(new Date()).format('YYYY-MM-DD'),
      gender: '',
      contact: '',
      email: '',
      weight: '',
      weight_type: '',
      country_code: '',
    };
  }

  AddUser = () => {
    if (this.state.name === '') {
      toast.error('name is required');
      return;
    } else if (this.state.gender === '') {
      toast.error('gender is required');
    } else if (this.state.dob === '') {
      toast.error('dob is required');
    } else if (this.state.contact === '') {
      toast.error('contact is required');
    } else if (this.state.email === '') {
      toast.error('email is required');
    } else if (this.state.weight === '') {
      toast.error('weight  is required');
    } else if (this.state.weight_type === '') {
      toast.error('weight type is required');
    } else if (this.state.country_code === '') {
      toast.error('country code  is required');
    }

    this.setState({ isloading: true });
    fetch(global.api_link + 'addUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        dob: this.state.dob,
        gender: this.state.gender,
        contact: this.state.contact,
        email: this.state.email,
        weight: this.state.weight,
        weight_type: this.state.weight_type,
        country_code: this.state.country_code,
        status: 'active',
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Profile Add Succesfully...');
          this.props.navigate('/user');
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      .finally(() => {
        this.setState({
          isloading: false,
        });
      });
  };
  render() {
    return (
      <>
        <Header />

        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          <section id="main-content" className="">
            <div className="wrapper main-wrapper row">
              <div className="clearfix" />
              {/* MAIN CONTENT AREA STARTS */}
              <div className="col-xs-12 mb-30">
                <div className="col-lg-12">
                  <section className="box">
                    <header className="panel_header">
                      <h2 class="title pull-left">Basic Info</h2>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div>
                          <div className="col-xs-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Name</label>
                                  <span className="desc" />
                                  <div className="controls">
                                    <input
                                      type="text"
                                      placeholder="Enter your name"
                                      className="form-control"
                                      value={this.state.name}
                                      onChange={(e) => {
                                        this.setState({ name: e.target.value });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Gender</label>
                                  <div className="controls">
                                    <select
                                      className="form-control"
                                      value={this.state.gender}
                                      onChange={(e) => {
                                        this.setState({
                                          gender: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="select">
                                        Open this select menu
                                      </option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="others">Others</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Weight</label>
                                  <span className="desc" />
                                  <div className="controls">
                                    <input
                                      type="number"
                                      placeholder="Enter your weight"
                                      className="form-control"
                                      value={this.state.weight}
                                      onChange={(e) =>
                                        this.setState({
                                          weight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Type</label>
                                  <div className="controls">
                                    <select
                                      className="form-control"
                                      value={this.state.weight_type}
                                      onChange={(e) =>
                                        this.setState({
                                          weight_type: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="select">
                                        Open this select menu
                                      </option>
                                      <option value="kg">Kg</option>
                                      <option value="pounds">Pounds</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">
                                    Date of Birth
                                  </label>

                                  <div className="controls">
                                    <input
                                      type="date"
                                      className="form-control w-100"
                                      value={this.state.dob}
                                      onChange={(e) =>
                                        this.setState({
                                          dob: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        C. Code
                                      </label>
                                      <div className="controls">
                                        <select
                                          className="form-control"
                                          value={this.state.country_code}
                                          onChange={(e) => {
                                            this.setState({
                                              country_code: e.target.value,
                                            });
                                          }}
                                        >
                                          <option>Choose</option>
                                          <option value="+91">+91</option>
                                          <option value="+93">+93</option>
                                          <option value="+94">+94</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="form-group">
                                      <label className="form-label" for="phone">
                                        Contact Number
                                      </label>

                                      <div className="controls">
                                        <input
                                          type="tel"
                                          placeholder="Enter your phone number"
                                          className="form-control"
                                          id="phone"
                                          value={this.state.contact}
                                          onChange={(e) => {
                                            this.setState({
                                              contact: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">Email</label>
                                  <input
                                    type="email"
                                    placeholder="Enter your email address"
                                    className="form-control"
                                    value={this.state.email}
                                    onChange={(e) =>
                                      this.setState({
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pull-right">
                            {this.state.isloading ? (
                              <button className="btn btn-primary mt-10 btn-corner disabled">
                                <div class="spinner-border" role="status"></div>
                                Please wait
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary mt-10 btn-corner"
                                onClick={(e) => {
                                  this.AddUser();
                                }}
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/* MAIN CONTENT AREA ENDS */}
            </div>
          </section>
        </div>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  const location = useLocation();
  return (
    <Adduser {...props} {...useParams()} navigate={abcd} location={location} />
  );
}

export default (props) => <Navigate {...props} />;
