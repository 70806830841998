import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Dna } from 'react-loader-spinner';

export class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      open: false,
      openEdit: false,
      category: [],
      loading: true,
      addDiseaseName: '',
      addDiseaseImage: [],
      editId: '',
      addButtonLoader: false,
      editDiseaseName: '',
      editDiseaseImage: null,
      editedImage: false,
      parent: '',
      addSelectedParentId: 0,
      editSelectedParentId: '',
      search: '',
      booking_id: '',
      dataLoader: false,
    };
  }

  componentDidMount() {
    this.fetc_bookings_admin('all');
  }

  fetc_bookings_admin = (e) => {
    this.setState({
      dataLoader: true,
    });
    fetch(global.api_link + 'fetch_bookings_admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        status: e,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.booking,
            isLoading: false,
            dataLoader: false,
          });
        } else {
          this.setState({
            category: [],
            isLoading: false,
            dataLoader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  search_booking = (e) => {
    fetch(global.api_link + 'search_bookings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        search: e,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          category: json.booking,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content">
              <div className="wrapper main-wrapper row">
                <div className="col-md-12">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                      <ul className="nav nav-tabs">
                        <li
                          className="active"
                          onClick={() => {
                            this.fetc_bookings_admin('all');
                          }}
                        >
                          <a data-toggle="tab" aria-expanded="false">
                            All
                          </a>
                        </li>
                        <li
                          className=""
                          onClick={() => {
                            this.fetc_bookings_admin('pending');
                          }}
                        >
                          <a data-toggle="tab" aria-expanded="false">
                            Pending
                          </a>
                        </li>
                        <li
                          className=""
                          onClick={() => {
                            this.fetc_bookings_admin('confirm');
                          }}
                        >
                          <a data-toggle="tab" aria-expanded="false">
                            Confirmed
                          </a>
                        </li>
                        <li
                          className=""
                          onClick={() => {
                            this.fetc_bookings_admin('cancel');
                          }}
                        >
                          <a data-toggle="tab" aria-expanded="false">
                            Cancelled
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-6 align-items-stretch">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                          this.search_booking(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {this.state.dataLoader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      height: '70vh',
                    }}
                  >
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                ) : (
                  <div className="col-lg-12">
                    <section className="box ">
                      <div className="content-body">
                        <div className="row">
                          <div className="col-xs-12">
                            <div
                              className="table-responsive"
                              data-pattern="priority-columns"
                            >
                              <table
                                id="tech-companies-1"
                                className="table vm table-small-font no-mb table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th>Sno</th>
                                    <th>Booking Id</th>
                                    <th>User Name</th>
                                    <th>User Email</th>
                                    <th>User Contact</th>
                                    <th>Booking Date</th>
                                    <th>Hospital Name</th>
                                    <th>Booking Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.category.map((values, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{values.booking_id}</td>
                                        <td>{values.user.name}</td>
                                        <td>{values.user.email}</td>
                                        <td>
                                          {values.user.country_code}
                                          {values.user.contact}
                                        </td>
                                        <td>{values.appointment_date}</td>
                                        <td>{values.hospital.name}</td>
                                        <td
                                          style={
                                            values.status === 'cancel'
                                              ? {
                                                  color: '#cc0000',
                                                }
                                              : values.status === 'pending'
                                              ? {
                                                  color: '#1b98b7',
                                                }
                                              : {
                                                  color: '#20ad00',
                                                }
                                          }
                                        >
                                          {values.status === 'pending'
                                            ? 'Pending'
                                            : values.status === 'confirm'
                                            ? 'Accepted'
                                            : 'Cancelled'}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center gap-2">
                                            <Link
                                              to={
                                                '/bookingdetails/' +
                                                values.booking_id
                                              }
                                            >
                                              {' '}
                                              <button
                                                type="button"
                                                className="btn btn-success"
                                              >
                                                <i className="fa-solid fa-eye" />
                                              </button>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

export default Bookings;
