import React, { Component } from 'react';
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

class Disease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      open: false,
      openEdit: false,
      category: [],
      loading: true,
      addDiseaseName: '',
      addDiseaseImage: [],
      editId: '',
      addButtonLoader: false,
      editDiseaseName: '',
      editDiseaseImage: null,
      editedImage: false,
      parent: '',
      addSelectedParentId: 0,
      editSelectedParentId: '',
      search: '',
    };
  }

  componentDidMount() {
    this.fetchCategory();
  }

  fetchCategory = () => {
    fetch(global.api_link + 'getcategoryList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.disease_category,
            isLoading: false,
          });
        } else {
          this.setState({
            category: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deleteCategory = (e) => {
    Swal.fire({
      title: ' Do you want to Delete this Disease ? ',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.api_link + 'deleteCategory/' + e, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              toast.success('Disease Deleted Successfully');
              this.fetchCategory();
            } else {
              toast.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  uploadImage = async (e) => {
    let image = this.state.addDiseaseImage;
    image.push(e.target.files[0]);
    this.setState({
      addDiseaseImage: image,
    });
  };

  addCategory = () => {
    var form = new FormData();
    form.append('name', this.state.addDiseaseName);
    form.append('status', 'active');
    form.append('parent_id', '0');
    if (this.state.addDiseaseImage.length > 0) {
      this.state.addDiseaseImage.map((item) => {
        form.append('image', item);
      });
    }
    this.setState({ addButtonLoader: true });
    fetch(global.api_link + 'addCategory', {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Disease added Successfully!');
          this.setState({
            open: false,
            addDiseaseName: '',
            addDiseaseImage: [],
            addSelectedParentId: 0,
          });
          this.fetchCategory();
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addButtonLoader: false });
      });
  };

  uploadEditImage = async (e) => {
    this.setState({
      editedImage: true,
      editDiseaseImage: e.target.files[0],
    });
  };

  EditCategory = () => {
    var form = new FormData();
    form.append('name', this.state.editDiseaseName);
    form.append('status', 'active');
    form.append('parent_id', '0');
    if (this.state.editedImage) {
      form.append('image', this.state.editDiseaseImage);
    }
    this.setState({ addButtonLoader: true });
    fetch(global.api_link + 'categoryProfileUpdate/' + this.state.editId, {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          toast.success('Disease Edited Successfully!');
          this.setState({
            openEdit: false,
            addDiseaseName: '',
            editDiseaseImage: [],
            editedImage: false,
            editSelectedParentId: '',
          });
          this.fetchCategory();
        } else {
          toast.error(json.errors[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ addButtonLoader: false });
      });
  };

  search_category = (e) => {
    fetch(global.api_link + 'search_category?query=' + e, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            category: json.data,
            isLoading: false,
          });
        } else {
          this.setState({
            category: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-container row-fluid container-fluid">
          <Sidenav />
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <section id="main-content">
              <div className="wrapper main-wrapper row">
                <div class="col-md-10 align-items-stretch my-3">
                  <input
                    className="form-control mx-2 mt-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                      this.search_category(e.target.value);
                    }}
                  />
                </div>
                <div class="col-lg-2 d-flex  justify-content-end my-3">
                  <button
                    type="button"
                    className="btn btn-success btn-icon btn-lg mt-10"
                    onClick={() => this.setState({ open: true })}
                  >
                    <span>Add Disease</span>
                  </button>
                </div>
                <div className="col-lg-12">
                  <section className="box ">
                    <div className="content-body">
                      <div className="row">
                        <div className="col-xs-12">
                          <div
                            className="table-responsive"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table vm table-small-font no-mb table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Sno</th>
                                  <th>Image</th>
                                  <th>Disease Name</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.category.map((values, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={global.img_link + values.image}
                                          style={{
                                            height: '40px',
                                            width: '40px',
                                          }}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {values.name}
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center gap-2">
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                              this.setState({
                                                openEdit: true,
                                                editId: values.id,
                                                editDiseaseName: values.name,
                                                editDiseaseImage: values.image,
                                                editSelectedParentId:
                                                  values.parent_id,
                                              });
                                            }}
                                          >
                                            <i className="fa-solid fa-pencil" />
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              this.deleteCategory(values.id);
                                            }}
                                          >
                                            <i className="fa-solid fa-trash" />
                                          </button>
                                          <Link
                                            to={'/questionspage/' + values.id}
                                            type="button"
                                            className="btn btn-success"
                                          >
                                            <i className="fa-solid fa-question"></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          )}
        </div>
        {/* add */}
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Add Disease</h3>
                <div className="form-group">
                  <div className="controls">
                    <label className="form-label mt-2" htmlFor="pro-start-date">
                      Disease Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.addDiseaseName}
                      onChange={(e) => {
                        this.setState({ addDiseaseName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Disease Image</label>
                  <div className="controls">
                    {this.state.addDiseaseImage.length > 0 &&
                      this.state.addDiseaseImage[
                        this.state.addDiseaseImage.length - 1
                      ] && (
                        <img
                          src={URL.createObjectURL(
                            this.state.addDiseaseImage[
                              this.state.addDiseaseImage.length - 1
                            ]
                          )}
                          alt="Disease Image"
                          className="img-fluid"
                          style={{
                            width: '200px',
                            height: '200px',
                          }}
                        />
                      )}
                    <input
                      type={'file'}
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      onChange={(e) => {
                        this.uploadImage(e);
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addButtonLoader ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.addCategory();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* edit */}
        <Modal
          open={this.state.openEdit}
          onClose={() =>
            this.setState({ openEdit: false, editDiseaseImage: [] })
          }
          focusTrapped={false}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body2">
                <h3 className="headsss">Edit Disease</h3>
                <div className="form-group">
                  <label className="form-label mt-2" htmlFor="pro-start-date">
                    Disease Name
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.editDiseaseName}
                      onChange={(e) => {
                        this.setState({ editDiseaseName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Disease Image</label>
                  <div className="controls">
                    {this.state.editedImage ? (
                      <img
                        id="target"
                        src={URL.createObjectURL(this.state.editDiseaseImage)}
                        style={{
                          width: '200px',
                          height: '200px',
                        }}
                      />
                    ) : this.state.editDiseaseImage != null ? (
                      <img
                        id="target"
                        src={global.img_link + this.state.editDiseaseImage}
                        style={{
                          width: '200px',
                          height: '200px',
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <input
                      type={'file'}
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      onChange={(e) => {
                        this.uploadEditImage(e);
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  {this.state.addButtonLoader ? (
                    <button className="btn btn-primary btn-corner disabled">
                      <div className="spinner-border" role="status"></div>
                      Please wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded"
                      onClick={() => {
                        this.EditCategory();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Disease;
